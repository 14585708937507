import { useCallback, useEffect, useState } from "react";
import { Box, Stack, Button } from "@mui/material";

import {
  fetchAllMerchant,
  downloadMerchants,
} from "../../../services/actions/staffMakerChecker.actions";

import MerchantReportsTable from "../../../components/tables/admin/MerchantReportsTable";
import TableLayout, { scrollToTableTop } from "../../../layouts/tables/TableLayout";
import CustomPagination from "../../../components/tables/CustomPagination";
import { alertError, alertSuccess } from "../../../utils/helperFunctions";
import ExportButton from "../../../components/buttons/ExportButton";
import DateRange from "../../../components/search inputs/DateRange";

const MerchantReports = () => {
  const [page, setPage] = useState(1);
  const [tableRows, setTableRows] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setisLoading] = useState(false);
  const [searchParams, setSearchParams] = useState({});
  const [totalMerchants, setTotalMerchants] = useState(0);
  const [isDownloading, setisDownloading] = useState(false);

  const searchDateParamHandler = (prop, date) => {
    setSearchParams({
      ...searchParams,
      [prop]: date,
    });
  };

  const handleSearch = () => {
    fetchAllMerchants(searchParams);
  };

  const handleDateReset = () => {
    delete searchParams?.startDate;
    delete searchParams?.endDate;
  };

  const paginationHandler = (prop) => {
    scrollToTableTop();
    setPage(prop);
    fetchAllMerchants(searchParams, prop);
  };

  const exportHandler = async (prop) => {
    setisDownloading(true);
    try {
      const response = await downloadMerchants(prop, searchParams);
      if (response) {
        alertSuccess("File downloaded");
      }
    } catch (error) {
      alertError(error);
    } finally {
      setisDownloading(false);
    }
  };

  const fetchAllMerchants = useCallback(async (params, page) => {
    setisLoading(true);
    try {
      const response = await fetchAllMerchant(page, params);
      if (response) {
        setTableRows(response?.data || []);
        setPage(response?.currentPageNumber || 1);
        setTotalPages(response?.totalPages || 0);
        setTotalMerchants(response?.totalRecords || 0);
      }
    } catch (error) {
      alertError(error);
    } finally {
      setisLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchAllMerchants();
  }, [fetchAllMerchants]);

  return (
    <div>
      <div className="flex flex-col xl:flex-row gap-4 justify-between mb-[40px] mt-[32px]">
        <h1 className="pageHeader min-w-fit">Merchant Reports</h1>
        <div className="flex flex-col md:flex-row gap-3 flex-wrap justify-between xl:gap-1">
          <DateRange
            className="dateRange admin-transactions"
            width={150}
            setDate={searchDateParamHandler}
            onReset={handleDateReset}
          />
          <Stack
            direction="row"
            gap="10px"
            className="action-buttons xl:ml-2 flex gap-4"
            sx={{ height: "40px" }}
          >
            <Button
              sx={{
                width: "140px",
                height: "100%",
                flexGrow: { xs: 1, md: 0 },
              }}
              variant="outlined"
              disableElevation
              onClick={handleSearch}
              disabled={isDownloading || isLoading}
            >
              Search
            </Button>
            <Box
              sx={{
                width: "140px",
                height: "100%",
                flexGrow: { xs: 1, md: 0 },
              }}
            >
              <ExportButton
                onExport={exportHandler}
                disabled={isLoading}
                isLoading={isDownloading}
              />
            </Box>
          </Stack>
        </div>
      </div>
      <TableLayout isLoading={isLoading}>
        <MerchantReportsTable
          refresh={() => fetchAllMerchants(searchParams, page)}
          tableData={tableRows}
          totalMerchants={totalMerchants}
        />
        {totalPages > 1 && (
          <CustomPagination
            paginationHandler={paginationHandler}
            totalPages={totalPages}
            page={page}
          />
        )}
      </TableLayout>
    </div>
  );
};

export default MerchantReports;
