import "./index.css";
import { useNavigate } from "react-router-dom";

import { IMAGES } from "../../assets";
import Spinner from "../../utils/Spinner";

const FormContainer = ({ children, sub, isLoading }) => {
  const navigate = useNavigate();
  return (
    <div className="auth-container">
      <div className="form-container">
        {isLoading && <Spinner />}
        <div className="form-header">
          <div
            className="logo"
            onClick={() => {
              navigate("/");
            }}
          >
            <img src={IMAGES.logo} alt="FERN Logo" />
          </div>
          <p className="sub text-center">{sub}</p>
        </div>
        {children}
      </div>
    </div>
  );
};

export default FormContainer;
