import { Link, useNavigate } from "react-router-dom";
import "./index.css";

import { ICONS, IMAGES } from "../../../assets";

const Footer = () => {
  const navigate = useNavigate();

  const usefulLinks = [
    {
      name: "Docs",
      path: "/documentation",
    },
    {
      name: "Demo",
      path: "/demo-store",
    },
    {
      name: "Terms & Conditions",
      path: "/terms-and-conditions",
    },
    {
      name: "Privacy Policy",
      path: "/privacy-policy",
    },
  ];
  return (
    <div className="footer-container">
      <div className="useful-info">
        <div className="left-side">
          <div className="logo">
            <img
              src={IMAGES.logo}
              alt="Fern Logo"
              onClick={() => navigate("/")}
            />
          </div>

          <p className="short-desc">Easy payments with FERN Payment Gateway</p>
        </div>
        <div className="right-side">
          <div className="useful-links">
            <h3 className="title">USEFUL LINKS</h3>
            <div className="links">
              {usefulLinks.map((link, index) => {
                return (
                  <Link className="link" key={index} to={link.path}>
                    {link.name}
                  </Link>
                );
              })}
            </div>
          </div>
          <div className="contact-us">
            <h3 className="title">CONTACT US</h3>
            <div className="contact-links">
              <div className="contact-link">
                <img src={ICONS.phone} alt="phone" />
                <span>07003290000</span>
              </div>
              <div className="contact-link">
                <img src={ICONS.mailOutlined} alt="mail" />
                <span>
                  <a href="mailto:support@fernng.com">support@fernng.com</a>
                </span>
              </div>
            </div>
            <div className="socials">
              <a
                target="_blank"
                rel="noreferrer"
                className="social-link"
                href="https://twitter.com/MyFCMB?t=r3KCBKzu6p36WHsxHXA_lg&s=09"
              >
                <img src={ICONS.twitter} alt="twitter" />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                className="social-link"
                href="https://instagram.com/myfcmb?igshid=MzRlODBiNWFlZA=="
              >
                <img src={ICONS.instagram} alt="instagram" />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                className="social-link"
                href="https://www.facebook.com/FCMBMyBank?mibextid=ZbWKwL"
              >
                <img src={ICONS.facebook} alt="facebook" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <p className="copyright">
        © FERN Payment Gateway {new Date().getFullYear()}
      </p>
    </div>
  );
};

export default Footer;
