import { toast } from "react-toastify";

const CryptoJS = require("crypto-js");

export const formatAddress = (address) => {
  if (address.length < 24) {
    return address;
  } else {
    return address.slice(0, 24) + "..";
  }
};

export const numberWithCommas = (number) => {
  if (typeof number === "string") {
    return number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return number
      ?.toFixed(2)
      ?.toString()
      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
};

export const calculateSessionTime = (tokenExpirationTime) => {
  if (tokenExpirationTime) {
    const convertedExpiryDate = new Date(tokenExpirationTime).getTime();
    const currentDate = new Date().getTime();
    const remainingDuration = convertedExpiryDate - currentDate;

    return remainingDuration;
  }
  return null;
};

export const generateRequestId = () => {
  const newId = (
    Date.now().toString(36).substr(2, 9) + Math.random().toString(36).substr(2, 10)
  ).toUpperCase();

  return newId;
};

const base64ToArrayBuffer = (base64) => {
  const binaryString = window.atob(base64);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    let ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
};

export const downloadFile = (data, type, name) => {
  const byte = base64ToArrayBuffer(data);
  const blob = new Blob([byte], { type: type });
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  const fileName = name;
  link.download = fileName;
  link.click();
};

export const isEmpty = (obj) => {
  return Object.keys(obj).length;
};

export const formatDate = (dateString) => {
  if (dateString) {
    const newDate = new Date(dateString);
    const datetime =
      newDate.getDate() +
      "-" +
      (newDate.getMonth() + 1) +
      "-" +
      newDate.getFullYear() +
      " | " +
      newDate.getHours() +
      ":" +
      newDate.getMinutes() +
      ":" +
      newDate.getSeconds();

    return datetime;
  }
  return "date not found";
};

export const shortenText = (text, length = 40) => {
  let myTruncatedString = text;
  if (myTruncatedString.length > length) {
    myTruncatedString = text.substring(0, length - 3) + "...";
  }
  return myTruncatedString;
};

export const uniqueArray = (arr) =>
  arr?.length > 0
    ? arr?.filter(
        (object, index) =>
          index === arr?.findIndex((obj) => JSON.stringify(obj) === JSON.stringify(object))
      )
    : [];

export function createGuid() {
  function s4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }
  return (
    s4() +
    s4() +
    "-" +
    s4() +
    "-4" +
    s4().substr(0, 3) +
    "-" +
    s4() +
    "-" +
    s4() +
    s4() +
    s4()
  ).toLowerCase();
}

export const generateHeader = () => {
  let dateToUse = new Date();
  let UTCTimestamp = dateToUse.toISOString().replace("Z", "");
  let dateInToken = UTCTimestamp.replace("T", "")
    .replace(":", "")
    .replace(":", "")
    .substring(0, UTCTimestamp.length - 7);
  let shaOneEncrypt = CryptoJS.SHA512(
    dateInToken + process.env.REACT_APP_CLIENT_ID + process.env.REACT_APP_CLIENT_PASSWORD
  );

  const apiHeader = {
    "x-correlation-id": createGuid(),
    "x-token": shaOneEncrypt.toString(CryptoJS.enc.Hex),
    "Ocp-Apim-Subscription-Key": process.env.REACT_APP_API_SUBSCRIPTION_KEY,
    "Ocp-Apim-Trace": true,
    UTCTimestamp: UTCTimestamp,
    "Cross-Origin-Opener-Policy": "same-origin",
    "X-Frame-Options": "SAMEORIGIN",
    "X-Content-Type-Options": "nosniff",
    "Content-Security-Policy": "frame-ancestors 'self' X-Frame-Options: DENY",
    client_id: process.env.REACT_APP_CLIENT_ID,
  };

  return apiHeader;
};

export const triggerGateway = (navigate, email, amount, name) => {
  const externalScript = document.createElement("script");

  externalScript.id = "external";

  externalScript.async = true;

  externalScript.type = "text/javascript";

  document.body.appendChild(externalScript);

  externalScript.src = process.env.REACT_APP_PAYMENT_GATEWAY_SCRIPT_URL;

  const callback = (response) =>
    navigate(`/demo-store?status=${response?.status}&transactionRef=${response?.transactionRef}`);

  const payload = {
    merchantCode: process.env.REACT_APP_PAYMENT_GATEWAY_MERCHANT_CODE,
    publicKey: process.env.REACT_APP_PAYMENT_GATEWAY_PUBLIC_KEY,
    onSuccess: callback,
    onFailure: callback,
    orderId: `PGP-${generateRequestId()}`,
    email: email,
    paymentOption: {
      card: true,
      payWithBank: true,
      payLater: true,
      USSD: true,
    },
    amount: amount,
    customerName: name,
  };

  externalScript.onload = () => {
    window["fernGatewayPlugin"].render(payload);
  };

  externalScript.remove();
};

export const isFileSizeValid = (size) => {
  return size / 1024 ** 2 <= 10;
};

export const isFileTypeValid = (type, fileTypes) => {
  const fileType = type.split(".").pop()?.toLowerCase();
  if (!fileType) return undefined;
  return fileTypes.includes(fileType);
};

export const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const alertSuccess = (message) => {
  toast.success(message || "Successful");
};

export const alertError = (error) => {
  toast.error(error?.message || "An error occurred");
};

export const downloadSampleFile = (url, name) => {
  const link = document.createElement("a");
  link.href = url;
  link.download = name;
  link.click();
};
