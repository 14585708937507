import { Navigate, useOutletContext } from "react-router-dom";
import MakerStatus from "./status/MakerStatus";
import { useSelector } from "react-redux";

const MakerDeclined = () => {
  const ref = useOutletContext();
  const userRole = useSelector(
    (state) => state?.userAuth?.data?.role
  )?.toLowerCase();
  return userRole === "maker" ? (
    <MakerStatus
      anchor={ref}
      noSub
      status="declined"
      title="Declined Requests"
    />
  ) : (
    <Navigate to="/not-found" replace />
  );
};

export default MakerDeclined;
