import { useState } from "react";
import { alertError, alertSuccess } from "../../utils/helperFunctions";
import { adminDeleteStaff } from "../actions/staffAdmin.actions";

const useDeleteStaff = () => {
  const [isDeleting, setIsDeleting] = useState(false);

  const deleteStaffUser = async (id) => {
    setIsDeleting(true);
    try {
      const result = await adminDeleteStaff(id);
      if (result) {
        alertSuccess("Deletion successful");
        // props?.reload();
      }
    } catch (error) {
      alertError(error);
    } finally {
      setIsDeleting(false);
    }
  };
  return { isDeleting, deleteStaffUser };
};

export default useDeleteStaff;
