import { useEffect, useCallback, useState } from "react";
import { Box } from "@mui/material";

import {
  fetchAllStatusMerchant,
  getMerchantByAccountNumber,
} from "../../../../../services/actions/staffMakerChecker.actions";
import CheckerDetailsTable from "../../../../../components/tables/admin/CheckerDetailsTable";
import TableLayout, { scrollToTableTop } from "../../../../../layouts/tables/TableLayout";
import CustomPagination from "../../../../../components/tables/CustomPagination";
import { alertError, isEmpty } from "../../../../../utils/helperFunctions";

const empty = {
  totalPages: 0,
  page: 1,
  totalMerchants: 0,
  tableRows: [],
};

const CheckerStatus = ({ action, status, title, search, actionType }) => {
  const [pageData, setPageData] = useState(empty);
  const [isLoading, setisLoading] = useState(false);

  const tableHeaders = [
    "Account Name",
    "Account Number",
    "Merchant Code",
    "Email Address",
    "Phone Number",
    action ? "Actions" : null,
  ];

  const paginationHandler = (prop) => {
    scrollToTableTop();
    setPageData({ ...pageData, page: prop });
    fetchAllMerchants(status, prop);
  };

  const searchParamsHandler = async (prop) => {
    setisLoading(true);
    try {
      const response = await getMerchantByAccountNumber(prop);
      if (response && isEmpty(response) > 0) {
        setPageData({
          totalPages: 1,
          page: 1,
          totalMerchants: 1,
          tableRows: isEmpty(response?.data) > 0 ? [response?.data] : response?.data,
        });
      }
    } catch (error) {
      setPageData({ ...empty });
    } finally {
      setisLoading(false);
    }
  };

  const fetchAllMerchants = useCallback(
    async (page) => {
      setisLoading(true);
      try {
        const response = await fetchAllStatusMerchant(status, page);
        if (response) {
          setPageData({
            totalPages: response?.totalPages || 0,
            page: response?.currentPageNumber || 1,
            totalMerchants: response?.totalRecords || 0,
            tableRows: response?.data || [],
          });
        }
      } catch (error) {
        alertError(error);
      } finally {
        setisLoading(false);
      }
    },
    [status]
  );

  useEffect(() => {
    fetchAllMerchants();
  }, [fetchAllMerchants]);

  return (
    <Box>
      <TableLayout isLoading={isLoading}>
        <CheckerDetailsTable
          refresh={() => fetchAllMerchants(pageData?.page)}
          title={title}
          tableData={pageData.tableRows}
          headers={tableHeaders}
          search={search}
          actionsType={actionType}
          action={action}
          view={status}
          searchHandler={searchParamsHandler}
        />
        {pageData.totalPages > 1 && (
          <CustomPagination
            paginationHandler={paginationHandler}
            totalPages={pageData.totalPages}
            page={pageData.page}
          />
        )}
      </TableLayout>
    </Box>
  );
};

export default CheckerStatus;
