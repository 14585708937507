import DashboardLayout from "../../../layouts/dashboard/DashboardLayout";
import TransactionsList from "./TransactionsList";

const Transactions = () => {
  return (
    <DashboardLayout>
      <TransactionsList header="Transactions" />
    </DashboardLayout>
  );
};

export default Transactions;
