import "./index.css";
import { Box, Stack } from "@mui/material";
import { useState, useCallback, useEffect } from "react";

import NotificationGroup from "../../../../../components/merchant/NotificationGroup";
import {
  getRestNotificationsState,
  updateNotifications,
} from "../../../../../services/actions/notifications.actions";
import { alertError } from "../../../../../utils/helperFunctions";

const AdminNotifications = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [accountUpdates, setAccountUpdates] = useState(empty);
  const [paymentAlerts, setPaymentAlerts] = useState(empty);
  const [userAlerts, setUserAlerts] = useState(empty);

  const fetchNotifications = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getRestNotificationsState();
      if (response) {
        setAccountUpdates({ ...response?.account });
        setPaymentAlerts({ ...response?.payment });
        setUserAlerts({ ...response?.user });
      }
    } catch (error) {
      alertError(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchNotifications();
  }, [fetchNotifications]);

  const handleChange = (prop) => async (event) => {
    try {
      const response = await updateNotifications(prop, event.target.name, event.target.checked);
      if (response) {
        switch (response?.class) {
          case "account":
            setAccountUpdates({
              ...accountUpdates,
              [response?.deliveryType]: response?.status,
            });
            break;
          case "payment":
            setPaymentAlerts({
              ...paymentAlerts,
              [response?.deliveryType]: response?.status,
            });
            break;
          case "user":
            setUserAlerts({
              ...userAlerts,
              [response?.deliveryType]: response?.status,
            });
            break;
          default:
            return;
        }
      }
    } catch (error) {
      alertError(error);
    }
  };

  return (
    <Box className="notifications-settings-container">
      <Stack gap="10px">
        <h1 className="title">Notification Settings</h1>
        <p className="sub-title">
          We may still send you important information about your account your settings
        </p>
      </Stack>
      <div className="settings-form">
        <NotificationGroup
          title="Account updates"
          subtitle="New log in, roles and permissions"
          hideSMS={true}
          state={accountUpdates}
          onChange={handleChange("account")}
          isLoading={isLoading}
        />
        <hr />
        <NotificationGroup
          title="Payment Alerts"
          subtitle="Credits, errors, and successful transactions"
          hideSMS={true}
          state={paymentAlerts}
          onChange={handleChange("payment")}
          isLoading={isLoading}
        />
        <hr />
        <NotificationGroup
          title="Users"
          subtitle="Approved, declined, and referred activities"
          hideSMS={true}
          state={userAlerts}
          onChange={handleChange("user")}
          isLoading={isLoading}
        />
      </div>
    </Box>
  );
};

export default AdminNotifications;

const empty = {
  push: false,
  sms: false,
  email: false,
};
