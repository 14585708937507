import "./App.css";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { ThemeProvider } from "@mui/material";
import { useIdleTimer } from "react-idle-timer";
import { useDispatch, useSelector } from "react-redux";
import { HubConnectionBuilder } from "@microsoft/signalr";

import store from "./store";
import theme from "./utils/Theme";
import AppRoutes from "./routes/Routes";
import useLogout from "./services/hooks/useLogout";
import { uniqueArray } from "./utils/helperFunctions";
import { systemControllersActions } from "./services/reducers/system.reducer";

export let stopSignalR;

function App() {
  const logoutUser = useLogout();
  const dispatch = useDispatch();
  const [connection, setConnection] = useState(null);
  const { notifications } = useSelector((state) => state.systemControllers);
  const isLoggedIn = useSelector((state) => state?.userAuth?.isLoggedIn);

  // idle logout
  const onIdle = () => {
    if (isLoggedIn) {
      logoutUser();
    }
  };

  useIdleTimer({
    timeout: 5 * 60 * 1000,
    onIdle: onIdle,
    debounce: 0,
  });

  // push notification
  useEffect(() => {
    if (isLoggedIn) {
      const newConnection = new HubConnectionBuilder()
        .withUrl(process.env.REACT_APP_BASE_URL + "/notify", {
          accessTokenFactory: () =>
            store.getState().userAuth?.data?.tokenResponse?.token,
        })
        .withAutomaticReconnect()
        .build();

      stopSignalR = async () => {
        try {
          await connection.stop();
        } catch (err) {}
      };

      setConnection(newConnection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then((result) => {
          connection.on("Notification", (message) => {
            toast(message?.title);
            const uniqueArr = uniqueArray([...notifications, message]);
            dispatch(systemControllersActions.setNotification(uniqueArr));
          });
        })
        .catch((e) => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connection]);

  return (
    <div className="App bg-[#fff]">
      <ThemeProvider theme={theme}>
        <AppRoutes />
      </ThemeProvider>
    </div>
  );
}

export default App;
