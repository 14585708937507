import "../index.css";
import { useState } from "react";
import { Checkbox } from "@mui/material";

import { StyledTableCell, StyledTableRow } from "../../../layouts/tables/TableLayout";
import CustomTable from "../CustomTable";

const tableHeads = ["", "Staff Id", "First Name", "Last Name", "Email Address", "Role"];

const SystemLockoutTable = (props) => {
  const handleChange = (id) => {
    let list = props.selected;
    if (list.includes(id)) {
      list = list.filter((item) => item !== id);
    } else {
      list.push(id);
    }
    props.setSelected(list);
  };

  return (
    <CustomTable headers={tableHeads}>
      {props?.tableRows?.map((row, index) => (
        <Row key={index} props={props} row={row} onChange={handleChange} />
      ))}
    </CustomTable>
  );
};

export default SystemLockoutTable;

const Row = ({ props, row, onChange }) => {
  const [checked, setChecked] = useState(props.selected?.includes(row.staffId) ? true : false);
  const handleChange = (id) => {
    setChecked(!checked);
    onChange(id);
  };
  return (
    <StyledTableRow props={props} hover>
      <StyledTableCell className='!py-1 !h-[40px]' component='th' scope='row'>
        {!row?.role?.toLowerCase()?.includes("super") && (
          <Checkbox
            checked={props.selected?.includes(row.staffId)}
            disabled={props.selected?.includes(row.staffId)}
            onChange={() => handleChange(row.staffId)}
            size='small'
            value={checked}
          />
        )}
      </StyledTableCell>
      <StyledTableCell className='min-w-[100px] !py-1 !h-[40px]'>{row?.staffId}</StyledTableCell>
      <StyledTableCell className='min-w-[120px] capitalize !py-1 !h-[40px]'>
        {row?.firstName?.toLowerCase()}
      </StyledTableCell>
      <StyledTableCell className='min-w-[100px] capitalize !py-1 !h-[40px]'>
        {row?.lastName?.toLowerCase()}
      </StyledTableCell>
      <StyledTableCell className='!py-1 !h-[40px]'>
        {row?.emailAddress?.toLowerCase()}
      </StyledTableCell>
      <StyledTableCell className='!py-1 !h-[40px]'>{row?.role}</StyledTableCell>
    </StyledTableRow>
  );
};
