const AppLabelValue = ({ label, value = "--", valueClass, children }) => {
  return (
    <div className="flex flex-col items-start gap-1">
      <p className="text-[10px] tracking-wider text-grey uppercase">{label}</p>
      {!children && (
        <p className={`font-medium text-black ${valueClass ? valueClass : ""}`}>{value}</p>
      )}
      {children}
    </div>
  );
};

export default AppLabelValue;
