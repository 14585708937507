import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Box } from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const DocxTable = ({ rows, headers, minWidth = 700 }) => {
  return (
    <Box
      className="table-container"
      sx={{
        background: "rgba(255, 255, 255, 0.5)",
        border: "1px solid #f1f1f1",
        borderRadius: "10px",
        width: "fit-content",
      }}
    >
      <Table sx={{ minWidth: minWidth, borderRadius: "10px" }} aria-label="customized table">
        <TableHead>
          <TableRow>
            {headers.map((head, i) => (
              <StyledTableCell sx={{ bgcolor: "#282c34 !important", fontWeight: "medium" }} key={i}>
                {head}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, i) => (
            <StyledTableRow key={i}>
              {Object.keys(row).map((key, i) => (
                <StyledTableCell key={i} scope="row">
                  {row[key]}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default DocxTable;
