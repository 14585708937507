import { Box } from "@mui/material";
import TransactionsList from "../transactions/TransactionsList";

const TransactionReports = () => {
  return (
    <Box>
      <TransactionsList header="Transaction Reports" isDownloadable={true} />
    </Box>
  );
};

export default TransactionReports;
