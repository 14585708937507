import { Box, CircularProgress } from "@mui/material";

const Spinner = ({ color, size }) => {
  const style = {
    background: "rgba(255, 255, 255, 0.7)",
  };
  return (
    <Box
      className="absolute h-full w-full top-0 left-0 z-20 grid justify-center items-center"
      sx={{ ...style }}
    >
      <CircularProgress color={color || "secondary"} size={size} />
    </Box>
  );
};

export default Spinner;
