import Axios from "../../config/Axios";
import store from "../../store";
import { generateRequestId } from "../../utils/helperFunctions";
import { userAuthActions } from "../reducers/userAuth.reducer";
import { decrypt, encrypt } from "../../utils/security";

export const allSubMerchants = async (merchantCode) => {
  try {
    const response = await Axios(`/merchant/sub-users?merchantCode=${merchantCode}`);
    const data = await response?.data?.data;
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.data || error?.message);
  }
};

export const createUser = async (details) => {
  const payload = details;
  payload.requestId = generateRequestId();
  try {
    const response = await Axios.post("/merchant/create-user", payload);
    const data = await response?.data?.data;
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.data || error?.message);
  }
};

export const updateUser = async (details) => {
  const payload = details;
  payload.requestId = generateRequestId();
  try {
    const response = await Axios.post("/merchant/update-user", payload);
    const data = await response?.data?.data;
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.data || error?.message);
  }
};

export const deleteUser = async (emailAddress) => {
  try {
    const response = await Axios.post(`/merchant/delete-user?emailAddress=${emailAddress}`);
    const data = await response?.data?.data;

    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.data || error?.message);
  }
};

export const updateProfile = async (details) => {
  const payload = details;
  payload.requestId = generateRequestId();
  try {
    const response = await Axios.post("/merchant/edit-profile", payload);
    const data = await response?.data?.data;
    let decryptedData = JSON.parse(decrypt(localStorage.getItem("PGP")));
    const userData = {
      ...decryptedData,
      data: { ...decryptedData.data, ...data },
    };
    localStorage.setItem("PGP", encrypt(JSON.stringify(userData)));

    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.data || error?.message);
  }
};

export const fetchToken = async (merchantCode) => {
  try {
    const response = await Axios(`/merchant/access-key?merchantCode=${merchantCode}`);
    const data = await response?.data?.data;
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message || error?.message);
  }
};

export const merchantGoLive = async () => {
  let currentUserData = JSON.parse(decrypt(localStorage.getItem("PGP")));
  try {
    const response = await Axios.post("/merchant/go-live");
    const userData = {
      ...currentUserData,
      data: { ...currentUserData.data, mode: "Live" },
    };
    localStorage.setItem("PGP", encrypt(JSON.stringify(userData)));
    store.dispatch(userAuthActions.update({ mode: "Live" }));

    return response;
  } catch (error) {
    throw new Error(error?.response?.data?.message || error?.message);
  }
};

export const getRestChannelsState = async () => {
  try {
    const response = await Axios("/merchant/payment-channels");
    const data = await response?.data?.data;
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.data || error?.message);
  }
};

export const updateChannels = async (channel, action) => {
  const payload = {
    requestId: generateRequestId(),
    channel,
    action,
  };
  try {
    const response = await Axios.post("/merchant/payment-channel/update", payload);
    const data = await response?.data?.data;
    if (data) {
      return payload;
    }
  } catch (error) {
    throw new Error(error?.response?.data?.message || error?.message);
  }
};

export const merchantDisableLive = async () => {
  let currentUserData = JSON.parse(decrypt(localStorage.getItem("PGP")));
  try {
    const response = await Axios.post("/merchant/disable-live");
    const userData = {
      ...currentUserData,
      data: { ...currentUserData.data, mode: "Test" },
    };
    localStorage.setItem("PGP", encrypt(JSON.stringify(userData)));
    store.dispatch(userAuthActions.update({ mode: "Test" }));

    return response;
  } catch (error) {
    throw new Error(error?.response?.data?.message || error?.message);
  }
};
