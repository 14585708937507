import React from "react";
import LayoutContainer from "./layoutContainer/LayoutContainer";
import LayoutMenu from "./layoutMenu/LayoutMenu";
import LayoutPageContent from "./layoutPageContent/LayoutPageContent";
import LayoutPageNav from "./layoutPageNav/LayoutPageNav";
import { useState } from "react";

const DashboardLayout = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => setIsOpen((p) => !p);
  return (
    <LayoutContainer>
      <LayoutMenu isOpen={isOpen} toggleNav={handleToggle} />
      <div className="layout-page w-full h-[100vh]">
        <LayoutPageNav toggleNav={handleToggle} />
        <LayoutPageContent>{children}</LayoutPageContent>
      </div>
    </LayoutContainer>
  );
};

export default DashboardLayout;
