import { toast } from "react-toastify";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import Spinner from "../../../utils/Spinner";
import AppCopyInput from "../../shared/AppCopyInput";
import { alertError } from "../../../utils/helperFunctions";
import { fetchToken, merchantGoLive } from "../../../services/actions/merchant.actions";

const GoLiveModal = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);
  const { merchantCode, mode, website, callbackUrl, displayName } = useSelector(
    (state) => state?.userAuth?.data
  );

  const [keys, setKeys] = useState({
    publicKey: "",
    secretKey: "",
  });
  const [showKey, setShowKey] = useState(false);

  const handleMouseDown = (e) => {
    e.preventDefault();
  };

  const fetchTokenHandler = useCallback(async () => {
    try {
      const response = await fetchToken(merchantCode);
      if (response) {
        setKeys({
          publicKey: response.publicKey,
          secretKey: response.secretKey,
        });
      }
    } catch (error) {
      return Promise.reject(error);
    }
  }, [merchantCode]);

  const handleGoLive = useCallback(async () => {
    // website url check
    if (website.length < 3) {
      toast.error("Please provide a website url");
      navigate("/merchant/settings/general");
      return;
    }
    // callbackUrl url check
    if (callbackUrl.length < 3) {
      toast.error("Please provide a callback url");
      navigate("/merchant/settings/general");
      return;
    }
    // display name empty check
    if (displayName.length === 0) {
      toast.error("Please provide a display name");
      navigate("/merchant/settings/general");
      return;
    }
    // display name length check
    if (displayName.length > 20) {
      toast.error(`Display name can not be longer than 20 characters (${20 - displayName.length})`);
      navigate("/merchant/settings/general");
      return;
    }

    setisLoading(true);
    try {
      const response = await merchantGoLive();
      if (response) {
        fetchTokenHandler();
      }
    } catch (error) {
      alertError(error);
    } finally {
      setisLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callbackUrl, dispatch, displayName, fetchTokenHandler, navigate, website]);

  return (
    <Dialog className="goLive-dialog" open={props.open} onClose={props.closeDialog} fullWidth>
      {isLoading && <Spinner />}
      <DialogTitle sx={{ m: 0, p: 2, fontWeight: 600 }} id="customized-dialog-title">
        {keys.publicKey.length > 2 ? "You are now live" : "Are you ready to go live"}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={props.closeDialog}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        {mode?.toLowerCase() === "test" ? (
          <Typography>
            If you are satisfied with the testing environment and ready to switch to live
            environment and start recieving real time payments, please click on the GO LIVE button
            below
          </Typography>
        ) : (
          <>
            <Box className="flex flex-col gap-4 w-[80%] mx-auto my-4">
              <AppCopyInput
                name="publicKey"
                label="Live Public Key"
                id="publicKey"
                className="input-section"
                value={keys.publicKey}
                fullWidth
                disabled
              />
              <AppCopyInput
                name="secretKey"
                label="Live Secret Key"
                id="secretKey"
                className="input-section"
                fullWidth
                required
                disabled
                type={showKey ? "text" : "password"}
                value={keys.secretKey}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowKey(!showKey)}
                        onMouseDown={handleMouseDown}
                        edge="end"
                      >
                        {showKey ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Typography>
              Copy the above credentials and use them to replace the test equivalent credentials in
              your setup
            </Typography>
          </>
        )}
      </DialogContent>
      <DialogActions>
        {mode?.toLowerCase() === "test" ? (
          <Button autoFocus variant="contained" onClick={handleGoLive}>
            Go Live
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
};

export default GoLiveModal;
