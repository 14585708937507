import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const AuthStaff = () => {
  const { role, userType } = useSelector((state) => state?.userAuth?.data);

  const isAdmin = role?.toLowerCase()?.includes("admin");
  const isStaff = userType?.toLowerCase()?.includes("staff");

  return isStaff && !isAdmin ? <Outlet /> : <Navigate to="/overview" />;
};

export default AuthStaff;
