import moment from "moment";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { Button, LinearProgress } from "@mui/material";

import SendIcon from "@mui/icons-material/Send";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";

import DisputeResponseModal from "../../../../components/modals/dispute/DisputeResponseModal";
import CloseDisputeModal from "../../../../components/modals/dispute/CloseDisputeModal";
import AppDetailsLayout from "../../../../components/shared/AppDetailsLayout";
import DashboardLayout from "../../../../layouts/dashboard/DashboardLayout";
import AppBreadcrumbs from "../../../../components/shared/AppBreadcrumbs";
import AppLabelValue from "../../../../components/shared/AppLabelValue";
import { numberWithCommas } from "../../../../utils/helperFunctions";
import useDispute from "../../../../services/hooks/useDispute";
import CommentCard from "./CommentCard";
import SendMessage from "./SendMessage";
import FileCard from "./FileCard";

const DisputeDetails = () => {
  const { tab, id } = useParams();
  const chatboxRef = useRef(null);
  const [isRespond, setIsRespond] = useState(false);
  const [isResolve, setIsResolve] = useState(false);
  const { userType } = useSelector((state) => state?.userAuth?.data);
  const {
    fetchSingle,
    singleDispute,
    isLoadingSingle,
    isUpdating,
    comments,
    isFetching,
    fetchComments,
  } = useDispute();

  const isStaff = userType?.toLowerCase() === "staff";
  const disputeStatus = singleDispute?.status?.toLowerCase();
  const breadCrumbs = [
    { name: `${tab} disputes`, path: `/disputes/${tab}` },
    { name: singleDispute?.code },
  ];

  const scrollToChatEnd = () => {
    if (chatboxRef.current) {
      chatboxRef.current.scrollTop = chatboxRef?.current?.scrollHeight;
    }
  };

  useEffect(() => {
    if (comments) {
      scrollToChatEnd();
    }
  }, [comments]);

  useEffect(() => {
    fetchSingle(id);
    fetchComments(id);
  }, [fetchComments, fetchSingle, id]);

  return (
    <DashboardLayout>
      {isLoadingSingle && (
        <div className='absolute top-0 w-full left-0'>
          <LinearProgress />
        </div>
      )}
      <div className='flex flex-col gap-5'>
        <AppBreadcrumbs routes={breadCrumbs} />
        {singleDispute && (
          <div className='grid grid-cols-[2fr_1fr] gap-[35px]'>
            <div className='flex flex-col w-full gap-5'>
              <AppDetailsLayout
                isLoading={isLoadingSingle || isUpdating}
                head={
                  <div className=' w-full flex justify-between items-center'>
                    <div className='flex items-center gap-1'>
                      <div className='w-2 h-2 rounded-[50px] bg-purple' />
                      <p className='uppercase text-xs tracking-wide font-medium text-black opacity-90'>
                        {singleDispute.status}
                      </p>
                    </div>
                    {!isStaff && singleDispute.status?.toLowerCase() === "open" && (
                      <Button
                        variant='outlined'
                        endIcon={<SendIcon />}
                        onClick={() => setIsRespond(true)}
                      >
                        Respond
                      </Button>
                    )}
                    {isStaff && singleDispute.status?.toLowerCase() === "active" && (
                      <Button
                        variant='outlined'
                        endIcon={<DoNotDisturbIcon />}
                        onClick={() => setIsResolve(true)}
                      >
                        Close
                      </Button>
                    )}
                  </div>
                }
              >
                <div className='grid grid-cols-3 gap-5 mt-2'>
                  <AppLabelValue
                    label='Date Initiated'
                    value={moment(singleDispute.dateCreated).format("DD MMM YYYY")}
                  />
                  {singleDispute.currency && (
                    <AppLabelValue label='Currency' value={singleDispute.currency?.toUpperCase()} />
                  )}
                  <AppLabelValue
                    label='Amount'
                    value={numberWithCommas(parseFloat(singleDispute.amount))}
                  />
                  {isStaff && (
                    <AppLabelValue label='Merchant ID' value={singleDispute.merchantCode} />
                  )}
                  <AppLabelValue
                    label='Customer Details'
                    value={singleDispute.customerName}
                    valueClass='capitalize'
                  />
                  <AppLabelValue
                    label='Transaction Reference'
                    value={singleDispute.transactionReference}
                  />
                  <AppLabelValue label='Payment Channel' value={singleDispute.channel} />
                  {singleDispute.status?.toLowerCase() !== "closed" && (
                    <AppLabelValue
                      label='Duration on Queue'
                      value={moment(singleDispute.dateCreated)
                        .add(1, "hour")
                        .fromNow()
                        .replace("in ", "")
                        .replace("a few seconds", "less than a minute")
                        .replace("ago", "")
                        .replace("a ", "1 ")}
                    />
                  )}
                  {singleDispute.status?.toLowerCase() === "closed" && (
                    <AppLabelValue
                      label='Date Closed'
                      value={moment(singleDispute.dateModified).format("DD MMM YYYY")}
                    />
                  )}
                </div>
              </AppDetailsLayout>
              {singleDispute.files?.length > 0 && (
                <AppDetailsLayout
                  isLoading={isLoadingSingle || isUpdating}
                  head={<p className='text-md font-semibold text-black opacity-95'>Documents</p>}
                >
                  <div className='flex flex-col gap-2 w-full'>
                    {singleDispute.files.map((file) => (
                      <FileCard
                        key={file.id}
                        disputeId={singleDispute.id}
                        onDelete={fetchSingle}
                        file={file}
                      />
                    ))}
                  </div>
                </AppDetailsLayout>
              )}
            </div>
            <AppDetailsLayout
              isLoading={isLoadingSingle || isUpdating || isFetching}
              className='pb-[150px] max-h-[80vh]'
              head={<p className='text-md font-semibold text-black opacity-95'>Comments</p>}
            >
              <div
                className='comments mt-2 h-full hide-scroll relative w-full flex flex-col gap-3'
                ref={chatboxRef}
              >
                {comments
                  ?.sort((a, b) => a.dateCreated > b.dateCreated)
                  ?.map((item) => (
                    <CommentCard
                      key={item.id}
                      disputeId={singleDispute.id}
                      onDelete={fetchComments}
                      comment={item}
                    />
                  ))}
              </div>
              {(disputeStatus === "active" || (disputeStatus === "open" && isStaff)) && (
                <SendMessage
                  onCommentPost={fetchComments}
                  onFileAdd={fetchSingle}
                  disputeId={singleDispute.id}
                />
              )}
            </AppDetailsLayout>
          </div>
        )}
      </div>
      <DisputeResponseModal
        open={isRespond}
        disputeId={singleDispute?.id}
        onClose={() => setIsRespond(false)}
      />
      <CloseDisputeModal
        open={isResolve}
        disputeId={singleDispute?.id}
        onClose={() => setIsResolve(false)}
      />
    </DashboardLayout>
  );
};

export default DisputeDetails;
