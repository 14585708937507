const AppDetailsLayout = ({ children, head, className, isLoading }) => {
  return (
    <div
      className={`relative border flex flex-col gap-3 border-gray p-[20px] rounded-[10px] bg-white ${
        className ? className : ""
      } ${isLoading ? "opacity-50 pointer-events-none" : ""}`}
    >
      {head}
      <hr className="border-gray" />
      {children}
    </div>
  );
};

export default AppDetailsLayout;
