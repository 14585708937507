import { useCallback, useState } from "react";
import {
  addStaffExemption,
  deleteStaffExemption,
  fetchExemptedStaff,
} from "../actions/staffAdmin.actions";
import { alertError, alertSuccess } from "../../utils/helperFunctions";

const useExemption = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState({
    entries: [],
    page: 1,
    totalPages: 0,
  });

  const fetchExemptedUsers = useCallback(async (params = {}, page = 1) => {
    setIsFetching(true);
    try {
      const response = await fetchExemptedStaff(params, page);
      if (response) {
        setData({
          entries: response?.data || [],
          page: response?.currentPageNumber || 1,
          totalPages: response?.totalPages || 0,
        });
      }
    } catch (error) {
      alertError(error);
      setError(error);
    } finally {
      setIsFetching(false);
    }
  }, []);

  const removeStaffUser = async (id) => {
    setIsRemoving(true);
    try {
      await deleteStaffExemption(id);
      alertSuccess("User removed successful");
    } catch (error) {
      alertError(error);
    } finally {
      setIsRemoving(false);
    }
  };

  const addStaffUser = async (list) => {
    setIsAdding(true);
    try {
      await addStaffExemption(list);
      alertSuccess("List updated successful");
    } catch (error) {
      alertError(error);
    } finally {
      setIsAdding(false);
    }
  };

  return {
    isFetching,
    error,
    data,
    fetchExemptedUsers,
    isRemoving,
    removeStaffUser,
    addStaffUser,
    isAdding,
  };
};

export default useExemption;
