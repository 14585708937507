import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { useCallback, useState, useEffect } from "react";

import NoTableData from "../../../components/tables/NoTableData";
import ExportButton from "../../../components/buttons/ExportButton";
import DashboardLayout from "../../../layouts/dashboard/DashboardLayout";
import IncomeTable from "../../../components/tables/merchant/IncomeTable";
import { alertError, alertSuccess } from "../../../utils/helperFunctions";
import CustomPagination from "../../../components/tables/CustomPagination";
import TableLayout, { scrollToTableTop } from "../../../layouts/tables/TableLayout";
import { fetchAllIncome, downloadIncome } from "../../../services/actions/transactions.actions";

const Income = () => {
  const merchantId = useSelector((state) => state?.userAuth?.data?.merchantCode)?.toLowerCase();
  const [isDownloading, setisDownloading] = useState(false);
  const [searchParams, setSearchParams] = useState({});
  const [isLoading, setisLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [tableRows, setTableRows] = useState([]);
  const [page, setPage] = useState(1);

  const searchHandler = (prop) => {
    setSearchParams(prop);
    fetchIncome(prop);
  };

  const paginationHandler = (prop) => {
    scrollToTableTop();
    setPage(prop);
    fetchIncome(searchParams, prop);
  };

  const exportHandler = async (prop) => {
    let params = searchParams;
    params.query = merchantId;
    setisDownloading(true);
    try {
      await downloadIncome(prop, params);
      alertSuccess("File downloaded");
    } catch (error) {
      alertError(error);
    } finally {
      setisDownloading(false);
    }
  };

  const fetchIncome = useCallback(
    async (params, page) => {
      setisLoading(true);
      try {
        const response = await fetchAllIncome(params, page, merchantId);
        if (response) {
          setTableRows(response?.data || []);
          setPage(response?.currentPageNumber || 1);
          setTotalPages(response?.totalPages || 0);
        }
      } catch (error) {
        alertError(error);
      } finally {
        setisLoading(false);
      }
    },
    [merchantId]
  );

  useEffect(() => {
    fetchIncome();
  }, [fetchIncome]);

  return (
    <DashboardLayout>
      <Box className="income-container">
        <div className="title-section">
          <h1 className="page-title">Income</h1>
          <Box className="action-buttons" sx={{ width: "150px", height: "40px" }}>
            <ExportButton onExport={exportHandler} isLoading={isDownloading} disabled={isLoading} />
          </Box>
        </div>
        <TableLayout isLoading={isLoading}>
          {tableRows?.length < 1 && <NoTableData link="Refresh" onClick={fetchIncome} />}
          {tableRows?.length > 0 && (
            <IncomeTable
              isDownloading={isDownloading}
              tableRows={tableRows}
              handleSearch={searchHandler}
              isLoading={isLoading}
            />
          )}
          {tableRows?.length > 0 && totalPages > 1 && (
            <CustomPagination
              paginationHandler={paginationHandler}
              totalPages={totalPages}
              page={page}
            />
          )}
        </TableLayout>
      </Box>
    </DashboardLayout>
  );
};

export default Income;
