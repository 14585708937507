import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const AuthMerchants = () => {
  const userType = useSelector(
    (state) => state?.userAuth?.data?.userType
  )?.toLowerCase();

  return userType?.includes("merchant") ? (
    <Outlet />
  ) : (
    <Navigate to="/overview" />
  );
};

export default AuthMerchants;
