import "../index.css";
import { useState } from "react";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";

import { StyledTableCell, StyledTableRow } from "../../../layouts/tables/TableLayout";
import ConfirmAction from "../../modals/confirmation/ConfirmAction";
import CustomTable from "../CustomTable";
import TextActions from "../TextActions";

const tableHeads = ["Staff Id", "First Name", "Last Name", "Email Address", "Role", "Actions"];

const StaffTable = (props) => {
  const myId = useSelector((state) => state?.userAuth?.data?.staffId);
  const [focusUser, setFocusUser] = useState(null);
  const [open, setOpen] = useState(false);

  const openDialog = (prop) => {
    setFocusUser(prop);
    setOpen(true);
  };

  const closeDialog = () => {
    setFocusUser(null);
    setOpen(false);
  };

  const confirmDelete = async () => {
    await props.onDelete(focusUser.staffId)
    closeDialog();
  };

  return (
    <>
      <ConfirmAction
        type="delete-modal"
        open={open}
        closeDialog={closeDialog}
        focusText={focusUser?.firstName + " " + focusUser?.lastName}
        confirmAction={confirmDelete}
        title="Are you sure you want to delete"
        description="This action is permanent and can not be reversed"
        isLoading={props.isLoading}
      />
      <Box className="staff-table">
        <div className="table-top">
          <h1 className="table-title">{props.title}</h1>
        </div>
        <CustomTable headers={tableHeads}>
          {props?.tableRows?.map((row, index) => (
            <StyledTableRow props={props} hover key={index}>
              <StyledTableCell component="th" scope="row">
                {row?.staffId}
              </StyledTableCell>
              <StyledTableCell sx={{ textTransform: "capitalize" }}>
                {row?.firstName?.toLowerCase()}
              </StyledTableCell>
              <StyledTableCell sx={{ textTransform: "capitalize" }}>
                {row?.lastName?.toLowerCase()}
              </StyledTableCell>
              <StyledTableCell>{row?.emailAddress?.toLowerCase()}</StyledTableCell>
              <StyledTableCell>{row?.role}</StyledTableCell>
              <StyledTableCell>
                {row?.staffId !== myId && (
                  <TextActions onEdit={props?.onEdit} onDelete={openDialog} row={row} />
                )}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </CustomTable>
      </Box>
    </>
  );
};

export default StaffTable;
