import "../index.css";
import { useState } from "react";
import { Box } from "@mui/material";

import { StyledTableCell, StyledTableRow } from "../../../layouts/tables/TableLayout";
import { alertError, alertSuccess } from "../../../utils/helperFunctions";
import { deleteUser } from "../../../services/actions/merchant.actions";
import ConfirmAction from "../../modals/confirmation/ConfirmAction";
import TextActions from "../TextActions";
import CustomTable from "../CustomTable";

const UsersTable = (props) => {
  const { setIsLoading, reload, onEdit, tableRows } = props;

  const [open, setOpen] = useState(false);
  const [focusUser, setFocusUser] = useState({});
  const tableHeads = ["Name", "Email Address", "Role", "Actions"];

  const openDialog = (prop) => {
    setFocusUser(prop);
    setOpen(true);
  };

  const closeDialog = () => {
    setFocusUser({});
    setOpen(false);
  };

  const confirmDelete = async () => {
    setIsLoading(true);

    try {
      const result = await deleteUser(focusUser.emailAddress);
      if (result) {
        alertSuccess("User deleted successfully");
        reload();
      }
    } catch (error) {
      alertError(error.message);
    } finally {
      setIsLoading(false);
      closeDialog();
    }
  };

  return (
    <>
      <ConfirmAction
        type="delete-modal"
        open={open}
        closeDialog={closeDialog}
        focusText={focusUser.fullName}
        confirmAction={confirmDelete}
        title="Are you sure you want to delete"
        description="This action is permanent and can not be reversed"
      />
      <Box className="users-table border rounded hide-scroll border-purple-20  border-opacity-20">
        <CustomTable headers={tableHeads}>
          {tableRows?.map((row, index) => (
            <StyledTableRow props={props} hover key={index}>
              <StyledTableCell className="capitalize" component="th" scope="row">
                {row?.fullName?.toLowerCase()}
              </StyledTableCell>
              <StyledTableCell>{row?.emailAddress?.toLowerCase()}</StyledTableCell>
              <StyledTableCell>{row?.role}</StyledTableCell>
              <StyledTableCell>
                <TextActions onEdit={() => onEdit(row)} onDelete={openDialog} row={row} />
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </CustomTable>
      </Box>
    </>
  );
};

export default UsersTable;
