import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#5c2f94",
    },
    secondary: { main: "#FFB81C" },
  },
});

export default theme;
