import { useEffect } from "react";
import DashboardLayout from "../../../layouts/dashboard/DashboardLayout";
import { matchRoutes, NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";

const defaultLinkStyle =
  "link min-w-fit flex h-[35px] items-center justify-center rounded-full px-3 text-sm ";

const SystemLockout = () => {
  const navigate = useNavigate();
  const nameUrl = useLocation();
  const route = [{ path: "/system-lockout/" }];

  const links = [
    {
      linkName: "Standard Hours",
      path: "standard",
    },
    {
      linkName: "Exempted Users",
      path: "exemption",
    },
  ];

  useEffect(() => {
    if (matchRoutes(route, nameUrl)) {
      navigate("standard", { replace: true });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <DashboardLayout>
      <div className='manage-staff-container'>
        <h1 className='page-title'>System Lockout</h1>
        <div className='lockout-navigation overflow-x-auto hide-scroll w-fit flex items-center gap-1 max-w-full rounded-full bg-gray p-1'>
          {links.map((link, index) => {
            return (
              <NavLink
                key={index}
                className={({ isActive }) =>
                  isActive
                    ? defaultLinkStyle + "bg-purple tracking-wide font-semibold text-white"
                    : defaultLinkStyle
                }
                to={link.path}
              >
                {link.linkName}
              </NavLink>
            );
          })}
        </div>
        <div className='md:mx-2'>
          <Outlet />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default SystemLockout;
