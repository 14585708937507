import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const FirstLoginChecker = () => {
  const { userFirstLogin, userType } = useSelector(
    (state) => state?.userAuth?.data
  );
  const isMerchant = userType?.toLowerCase()?.includes("merchant");

  return isMerchant && userFirstLogin ? (
    <Navigate to="/change-password" />
  ) : (
    <Outlet />
  );
};

export default FirstLoginChecker;
