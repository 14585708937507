import { useSelector } from "react-redux";
import MerchantOverview from "./merchant/MerchantOverview";
import AdminOverview from "./staff/AdminOverview";
import DashboardLayout from "../../../layouts/dashboard/DashboardLayout";

const Overview = () => {
  const { userType } = useSelector((state) => state?.userAuth?.data);
  const isMerchant = userType?.toLowerCase()?.includes("merchant");

  return (
    <DashboardLayout>
      {isMerchant ? (
        <MerchantOverview />
      ) : (
        <AdminOverview header="Transactions" />
      )}
    </DashboardLayout>
  );
};

export default Overview;
