const OnBoarding = () => {
  return (
    <>
      <h1 className="section-header">On-Boarding</h1>
      <p>
        Please fill the form below to confirm your eligibility for this service
        and get started
      </p>
      <a
        href="https://forms.microsoft.com/r/xzmzzKa6hm"
        target="_blank"
        rel="noreferrer"
        className="text-purple font-medium underline"
      >
        Eligibility Form
      </a>
    </>
  );
};

export default OnBoarding;
