import "./index.css";

// import { ICONS } from "../../assets";
import Spinner from "../../utils/Spinner";
import { numberWithCommas } from "../../utils/helperFunctions";

const TransactionCard = (props) => {
  return (
    <div className="relative transactions_card-container">
      {props.isLoading && <Spinner />}
      <div className="overview">
        <div className="title-section">
          <div
            className={`indicator ${props.title.includes("Failed") ? "failed" : "success"}`}
          ></div>
          <p className="title">{props.title}</p>
        </div>
      </div>
      <div className="amount">
        {/* <div className="symbol">
          <img src={ICONS.naira} alt="Naira" />
        </div> */}
        <h1 className="figure">{numberWithCommas(props.amount)}</h1>
      </div>
    </div>
  );
};

export default TransactionCard;
