import { useState } from "react";
import { Box, Stack } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import { StyledTableCell, StyledTableRow } from "../../layouts/tables/TableLayout";
import { numberWithCommas } from "../../utils/helperFunctions";

const ProductCard = ({ id, image, name, price, sku, updateTotal, onRemove }) => {
  const [values, setValues] = useState({
    quantity: 0,
    total: 0,
  });

  const calcTotal = (action) => {
    let quan = values.quantity;

    if (action === "add") {
      quan = quan + 1;
    } else if (quan < 1) {
      quan = 0;
    } else {
      quan = quan - 1;
    }

    if (action === "subtract" && values.quantity === 1) {
      removeHandler();
      return;
    }

    setValues({ quantity: quan, total: quan * price });
    updateTotal(id, price, quan, name);
  };

  const removeHandler = () => {
    setValues({
      quantity: 0,
      total: 0,
    });
    onRemove(id);
  };

  return (
    <StyledTableRow props={{ showPagination: false }}>
      <StyledTableCell component="th" scope="row">
        <Stack direction="row" alignItems="flex-start" gap="10px">
          <Box
            sx={{
              backgroundImage: `url(${image})`,
              backgroundSize: "cover",
              backgroundPosition: "center center",
            }}
            className="product-img"
            width="50px"
            height="50px"
          />
          <Stack className="product-details">
            <p className="name font-[500]  text-[16px]  text-black">{name}</p>
            <p className="sku text-[12px] opacity-[50%]">SKU: {sku}</p>
          </Stack>
        </Stack>
      </StyledTableCell>
      <StyledTableCell sx={{ minWidth: "130px" }}>
        <p className="price text-[16px] text-black">
          <span style={{ fontFamily: "Helvetica" }}>₦</span> {numberWithCommas(+price)}
        </p>
      </StyledTableCell>
      <StyledTableCell sx={{ minWidth: "140px" }}>
        <Stack direction="row" gap="0px" className="quantity">
          <Box
            width="26px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            bgcolor="#f2f2f2"
            className="cursor-pointer"
            onClick={() => calcTotal("subtract")}
            sx={{
              pointerEvents: values.quantity < 1 ? "none" : "default",
              "&:hover": {
                bgcolor: "#9579b8",
                svg: {
                  color: "#fff",
                },
              },
            }}
          >
            <RemoveIcon fontSize="22px" />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            border="1px solid #f2f2f2"
            className="value text-black"
            width="50px"
            height="30px"
          >
            {values.quantity}
          </Box>
          <Box
            width="26px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            bgcolor="#f2f2f2"
            className="cursor-pointer"
            onClick={() => calcTotal("add")}
            sx={{
              "&:hover": {
                bgcolor: "#9579b8",
                svg: {
                  color: "#fff",
                },
              },
            }}
          >
            <AddIcon fontSize="22px" />
          </Box>
        </Stack>
      </StyledTableCell>
      <StyledTableCell sx={{ minWidth: "130px" }}>
        <p className="total font-[500] text-[16px] text-black">
          <span style={{ fontFamily: "Helvetica" }}>₦</span> {numberWithCommas(+values.total)}
        </p>
      </StyledTableCell>
      <StyledTableCell sx={{ minWidth: "53px" }}>
        {values.quantity > 0 ? (
          <Box
            width="20px"
            height="20px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            bgcolor="#9579b8"
            className="cursor-pointer"
            onClick={removeHandler}
          >
            <RemoveIcon fontSize="22px" sx={{ color: "#fff" }} />
          </Box>
        ) : (
          ""
        )}
      </StyledTableCell>
    </StyledTableRow>
  );
};

export default ProductCard;
