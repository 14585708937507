import { useState } from "react";
import { useFormik } from "formik";
import { Button, DialogActions, DialogContent, TextField } from "@mui/material";

import ModalLayout from "../ModalLayout";
import AppUploader from "../../../shared/AppUploader";
import useDispute from "../../../../services/hooks/useDispute";
import sample from "../../../../assets/sample/dispute_sample.csv";
import { downloadSampleFile } from "../../../../utils/helperFunctions";
import { validateDisputeSchema } from "../../../../utils/formikFormValidators";

const tabs = [
  { id: 1, name: "Single upload" },
  { id: 2, name: "Bulk upload" },
];

const DisputeModal = ({ open, onClose, reload }) => {
  const [file, setFile] = useState(null);
  const [active, setActive] = useState(1);
  const { uploadNew, uploadBatch, isUploading } = useDispute();

  const formik = useFormik({
    initialValues: {
      transactionReference: "",
      comment: "",
    },
    validationSchema: validateDisputeSchema,
    onSubmit: (values) => {
      handleSingleSubmit(values);
    },
  });

  const handleSingleSubmit = async (values) => {
    const res = await uploadNew(values);
    if (res) {
      reload();
      handleClose();
    }
  };

  const handleBulkSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", file);

    const res = await uploadBatch(formData);
    if (res) {
      reload();
      handleClose();
    }
  };

  const handleClose = () => {
    formik.resetForm();
    setFile(null);
    setActive(1);
    onClose();
  };

  return (
    <ModalLayout
      isLoading={isUploading}
      className="dispute-modal"
      open={open}
      closeModal={handleClose}
    >
      <div className="w-full box-border px-3">
        <div className="w-full flex items-center justify-center gap-3 mt-3 mb-5">
          {tabs.map((link) => {
            return (
              <p
                className={`font-medium py-1 px-2 tracking-wide cursor-pointer ${
                  active === link.id
                    ? "text-purple border-b border-purple"
                    : "text-black opacity-50"
                }`}
                key={link.id}
                onClick={() => setActive(link.id)}
              >
                {link.name}
              </p>
            );
          })}
        </div>
        {active === 2 ? (
          <form className="modal-body" onSubmit={handleBulkSubmit}>
            <DialogContent className="form-content">
              <div className="flex flex-col gap-2">
                <div className="w-full h-[250px]">
                  <AppUploader onSelect={(file) => setFile(file)} fileTypes={["xlsx", "csv"]} />
                </div>
                <p
                  className="text-xs text-black opacity-60 cursor-pointer w-fit"
                  onClick={() => downloadSampleFile(sample, "Bulk Dispute Sample")}
                >
                  click to download sample file
                </p>
              </div>
            </DialogContent>
            <DialogActions className="modal-actions">
              <Button className="action-btn" onClick={handleClose} variant="outlined">
                Cancel
              </Button>
              <Button
                className="action-btn"
                variant="contained"
                color="primary"
                type="submit"
                disableElevation
              >
                Bulk Upload
              </Button>
            </DialogActions>
          </form>
        ) : (
          <form className="modal-body" onSubmit={formik.handleSubmit}>
            <DialogContent className="form-content">
              <TextField
                name="transactionReference"
                label="Transaction Reference"
                id="transactionReference"
                className="input-section"
                fullWidth
                required
                value={formik.values?.transactionReference}
                onChange={formik.handleChange}
                error={
                  formik.touched.transactionReference && Boolean(formik.errors.transactionReference)
                }
                helperText={
                  formik.touched.transactionReference && formik.errors.transactionReference
                }
              />
              <TextField
                name="comment"
                label="Comment"
                id="comment"
                className="input-section"
                fullWidth
                multiline
                rows={3}
                value={formik.values?.comment}
                onChange={formik.handleChange}
                error={formik.touched.comment && Boolean(formik.errors.comment)}
                helperText={formik.touched.comment && formik.errors.comment}
              />
            </DialogContent>
            <DialogActions className="modal-actions">
              <Button className="action-btn" onClick={handleClose} variant="outlined">
                Cancel
              </Button>
              <Button
                className="action-btn"
                variant="contained"
                color="primary"
                type="submit"
                disableElevation
              >
                Upload Dispute
              </Button>
            </DialogActions>
          </form>
        )}
      </div>
    </ModalLayout>
  );
};

export default DisputeModal;
