import "./index.css";
import { useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent } from "@mui/material";

import BlockIcon from "@mui/icons-material/Block";

import { merchantDisableLive } from "../../../services/actions/merchant.actions";
import { alertError } from "../../../utils/helperFunctions";
import Spinner from "../../../utils/Spinner";

const DisableLiveModal = ({ open, onClose }) => {
  const [isLoading, setisLoading] = useState(false);

  const handleDisableLive = async () => {
    setisLoading(true);
    try {
      await merchantDisableLive();
      onClose();
    } catch (error) {
      alertError(error);
    } finally {
      setisLoading(false);
    }
  };

  return (
    <Dialog className="disable-live-dialog" open={open} onClose={onClose} fullWidth>
      {isLoading && <Spinner />}
      <Box className="disable-live-dialog-inner">
        <div className="icon">
          <BlockIcon fontSize="large" color="primary" />
        </div>
        <DialogContent className="dialog-content">
          <p className="description text-black">Are you sure you want to disable live mode?</p>
        </DialogContent>
        <DialogActions className="dialog-actions">
          <Button color="primary" variant="outlined" disableElevation onClick={onClose}>
            No, Cancel
          </Button>
          <Button color="primary" variant="contained" disableElevation onClick={handleDisableLive}>
            Yes, Continue
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default DisableLiveModal;
