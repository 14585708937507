import "./index.css";
import { Link } from "react-router-dom";
import { Box, Stack } from "@mui/material";

import Header from "../../components/common/header/Header";
import Footer from "../../components/common/footer/Footer";
import Inner from "../../layouts/inner landing page/Inner";

const PrivacyPolicy = () => {
  return (
    <Box className="privacy-policy">
      <Header />
      <Box className="generic-banner lg:h-[450px] h-[300px]">
        <h2 className="font-[700] lg:text-[48px] md:text-[40px] text-[30px] text-center text-black opacity-90">
          Privacy Policy
        </h2>
      </Box>
      <section className="terms">
        <Inner>
          <Box
            sx={{
              m: { sm: "50px 0px", md: "100px" },
              p: { xs: "30px 20px 50px", sm: "50px 50px 100px" },
            }}
            className="border rounded-[10px] border-gray"
          >
            <Stack gap="40px" className="details">
              <ol className="flex decimal-ol flex-col gap-5">
                <li>
                  <p className="text-[18px] font-bold">
                    Your Privacy is important to us.
                  </p>
                  <p className="mt-3">
                    This privacy statement sets out the privacy policy of{" "}
                    <Link className="text-purple font-medium" to="/">
                      fernng.com
                    </Link>
                    , which provides a portal, or gateway, to the financial
                    services offered by FERN.
                  </p>
                  <p className="mt-2">
                    This policy explains how we collect, share, use, and protect
                    information when you visit or use this website and any other
                    online services, platforms, or products offered by FERN that
                    link to or reference this policy (collectively, our
                    “services”).
                  </p>
                  <ol className="mt-3 flex flex-col gap-2">
                    <li>
                      <p className="font-bold mt-2">FERN and You</p>
                      <p className="mt-2">
                        FERN is a subsidiary of First City Monument Bank Limited
                        is a private limited liability company registered in the
                        Federal Republic of Nigeria under RC No. 46713. Its
                        head-office is at Primrose Tower, 17A Tinubu Street,
                        Lagos State, Nigeria. FCMB Group Plc hosts the
                        <a
                          href="https://fcmbgroupplc.com"
                          target="_blank"
                          rel="noreferrer"
                          className="text-purple"
                        >
                          fcmbgroupplc.com
                        </a>{" "}
                        website and provides technical support, access and links
                        to the Local Sites of First City Group members.
                        <a
                          href="https://fcmbgroupplc.com"
                          target="_blank"
                          rel="noreferrer"
                          className="text-purple"
                        >
                          fcmbgroupplc.com
                        </a>{" "}
                        does not offer financial services or products. Financial
                        services and products may only be obtained by
                        registering with a Local Site. The First City Group
                        provides financial products and services to a global
                        clientele through its affiliated companies and branches
                        located in 36 states and the Federal Capital Territory
                        in Nigeria, and in the UK. Privacy and personal data
                        protection principles vary from one country to another.
                        When you access or link to a Local Site, please read the
                        privacy statement issued by the Local Site to determine
                        the policies that apply to information or data
                        maintained by the Local Site.
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p className="mt-2 text-[18px] font-bold">
                    Information we may collect about you
                  </p>
                  <ol className="flex flex-col mt-2 gap-2">
                    <li className="">
                      <p className="font-bold mt-2">
                        Your Personal Information
                      </p>
                      <p className="mt-2">
                        As a subsidiary of FCMB, FERN strives to meet your needs
                        and provide you with exceptional services. In the course
                        of consuming our services through various channels, such
                        as forms, phone calls, correspondence, service point
                        interfaces, and other available channels, we collect
                        information that you provide to us. This information may
                        include, but is not limited to, contact data,
                        log/Technical information, Financial Data, Marketing and
                        Communications Data, identity verification details (this
                        includes Personally Identifiable Information (PII),
                        otherwise known as Personal Information or Personal
                        Data, which includes email address, phone number,
                        contact address, limited financial information, location
                        data, device data etc.) and documents, services consumed
                        or desired, mode of consumption, preferences, location,
                        general events, and instructions and transactions
                        relating to the services. The lawful basis we rely on
                        for processing your Personal Information are:
                      </p>
                      <ul className="flex flex-col gap-2 list-disc">
                        <li>
                          Your Consent: Where you agree to us collecting your
                          Personal Information by using our Services.
                        </li>
                        <li>
                          We have a contractual obligation: Without your
                          Personal Information, we cannot provide our Services
                          to you.
                        </li>
                        <li>
                          We have a legal obligation: To ensure we are fully
                          compliant with all applicable financial legislations
                          such as Anti-Money Laundering and Countering the
                          Financing of Terrorism (AML/CFT) Laws, we must collect
                          and store your Personal Information. We protect
                          against fraud by checking your identity with your
                          Personal Information.
                        </li>
                      </ul>
                      <span className="mt-2">
                        Additionally, to better serve your needs, we may utilize
                        information about you collected from third parties and
                        service partners. It is important to note that these
                        third-party sources are not under the control of FERN,
                        and we are not responsible for how they use the
                        information.
                      </span>
                    </li>
                    <li>
                      <p className="font-bold mt-2">
                        Usage and other information
                      </p>
                      <p className="mt-2 ">
                        In addition to the personal information described above,
                        we may collect certain information about your use of our
                        online services. For example, we may capture the IP
                        address of the device you use to connect to the online
                        service, the type of operating system and browser you
                        use, and information about the site you came from, the
                        parts of our online service you access, and the site you
                        visit next. FERN a subsidiary of FCMB or our third-party
                        partners may also use cookies, web beacons or other
                        technologies to collect and store other information
                        about your visit to, or use of, our online services. In
                        addition, we may later associate the usage and other
                        information we collect online with personal information
                        about you.
                      </p>
                    </li>
                    <li>
                      <p className="font-bold mt-2">
                        Additional sources of information
                      </p>
                      <p className="mt-2">
                        We may also collect information about you from
                        additional online and offline sources including from
                        co-branded partner sites or commercially available
                        third-party sources, such as credit reporting agencies.
                        We may combine this information with the personal and
                        other information we have collected about you under this
                        Privacy Policy.
                      </p>
                    </li>
                    <li>
                      <p className="font-bold mt-2">Non-Personal Information</p>
                      <p className="mt-2">
                        In order to achieve our goal of providing you with the
                        best-in-class service, we may also collect, store, use
                        and transfer non-personal information or anonymized data
                        such as statistical or demographic data. These may be
                        collected or sourced during your visits to perform
                        certain tasks such as grant you access to some parts of
                        our web site or conduct research on your behavior on our
                        site in order to improve our services. We will not
                        disclose your information to any person outside our
                        organization except as described in this Privacy Policy.
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p className="text-[18px] font-bold">
                    Our Use of Information
                  </p>
                  <p className="mt-2">
                    FCMB and/or subsidiaries may use or process the information
                    discussed above in a number of ways, such as to:
                  </p>
                  <ul className="flex flex-col gap-2 list-disc">
                    <li>Manage your preferences;</li>
                    <li>
                      Create and manage any accounts or transactions you may
                      have with us, verify your identity, provide our services,
                      and respond to your inquiries;
                    </li>
                    <li>
                      Process your applications and transactions (including
                      authorization, clearing, chargebacks and other related
                      dispute resolution activities);
                    </li>
                    <li>
                      Protect against and prevent fraud, unauthorized
                      transactions, claims and other liabilities as well as
                      enhance the security of your account or our online
                      services;
                    </li>
                    <li>
                      Provide, administer and communicate with you about our
                      products, services, offers, programs and promotions as
                      well as those of our merchants and partners;
                    </li>
                    <li>
                      Evaluate your interest in employment and contact you
                      regarding possible employment with FCMB;
                    </li>
                    <li>
                      Evaluate and improve our business, including developing
                      new products and services;
                    </li>
                    <li>
                      To target advertisements, newsletters, and service
                      updates;
                    </li>
                    <li>
                      As necessary to establish, exercise and defend legal
                      rights;
                    </li>
                    <li>
                      Perform analytics concerning your use of our online
                      services, including your responses to our emails and the
                      pages and advertisements you view;
                    </li>
                    <li>
                      As may be required by applicable laws and regulations,
                      including for compliance with Know Your Customers and risk
                      assessment, Anti-Money Laundering, anti-corruption and
                      sanctions screening requirements, or as requested by any
                      judicial process, law enforcement or governmental agency
                      having or claiming jurisdiction over FCMB or affiliates;
                    </li>
                    <li>
                      To use data analytics to improve our Website, products, or
                      services, and user experiences;
                    </li>
                    <li>
                      For other purposes for which we provide specific notice at
                      the time you provide or we collect your information. We
                      may also use data that we collect on an aggregate or
                      anonymous basis (such that it does not identify any
                      individual customers) for various business purposes, where
                      permissible under applicable laws and regulations.
                    </li>
                  </ul>
                </li>
                <li>
                  <p className="text-[18px] font-bold">Cookies</p>
                  <p className="mt-2">
                    This website, along with most other major websites, uses
                    cookies. Cookies are pieces of information that a website
                    transfers to the cookie file on your computer’s hard disk.
                    Cookies enable users to navigate around the website and
                    (where appropriate) enable us to tailor the content to fit
                    the needs of visitors who have accessed the site.{" "}
                    <Link to="/" className="text-purple font-medium">
                      Fernng.com
                    </Link>{" "}
                    uses two types of cookies on this website:
                  </p>
                  <ul className="flex flex-col mt-2 gap-2 list-disc">
                    <li>
                      Session cookies, which are temporary cookies that remain
                      in the cookie file of your computer until you close your
                      browser (at which point they are deleted).
                    </li>
                    <li>
                      Persistent or stored cookies that remain permanently on
                      the cookie file of your computer.
                    </li>
                  </ul>
                  <p className="mt-2">
                    Cookies cannot look into your computer and obtain
                    information about you or your family or read any material
                    kept on your hard drive and, unless you have logged onto an
                    authenticated page, cookies cannot be used to identify who
                    you are.
                  </p>
                  <p className="mt-2">
                    Cookies cannot be used by anyone else who has access to the
                    computer to find out anything about you, other than the fact
                    that someone using the computer has visited a certain
                    website. Cookies do not in any way compromise the security
                    of your computer. Cookies will not be used to contact you
                    for marketing purposes other than by means of advertisements
                    offered within this website. Cookies may be used to record
                    details of pages relating to particular products and
                    services that you have visited on this website. This is to
                    provide fcmb.com with generic usage statistics to allow the
                    company to improve this website and to provide you with
                    information that may interest you.
                  </p>
                  <p className="mt-2">
                    The web browsers of most computers are initially set up to
                    accept cookies. If you prefer, you can set your web browser
                    to disable cookies or to inform you when a website is
                    attempting to add a cookie. You can also delete cookies that
                    have previously been added to your computer’s cookie file.
                    You can set your browser to disable persistent cookies
                    and/or session cookies but if you disable session cookies,
                    although you will be able to view this website’s unsecured
                    pages, you may not be able to log onto any authenticated
                    pages.
                  </p>
                  <p className="mt-2">
                    Please visit{" "}
                    <a
                      href="http://www.allaboutcookies.org/manage-cookies"
                      target="_blank"
                      rel="noreferrer"
                      className="text-[blue]"
                    >
                      http://www.allaboutcookies.org/manage-cookies
                    </a>{" "}
                    to discover how to disable and delete cookies.
                  </p>
                </li>
                <li>
                  <p className="mt-2 text-[18px] font-bold">Disclosures</p>
                  <ol className="flex mt-2 flex-col gap-2">
                    <li>
                      <p className="font-bold">Disclosures</p>
                      <p className="mt-2">
                        We may divulge individual data to any individual
                        performing review, lawful, operational, or different
                        services for us. We will utilize data which does not
                        identify the person for these exercises at whatever
                        point achievable. Data divulged to vendors or
                        contractors for operational purposes may not be
                        re-disclosed to others by such a vendor or contractor.
                        We may reveal individual data when needed to do as such
                        by a court request, or court order. We may divulge
                        individual data as we esteem it proper to secure the
                        wellbeing of our customers or for an investigation
                        identified with open security or to report an action
                        that has all the earmarks of being disregarding law. We
                        may divulge individual data to ensure the security and
                        dependability of this site and to take safety measures
                        against accountability.
                      </p>
                    </li>
                    <li>
                      <p className="font-bold">Disclosures to Third Parties</p>
                      <p className="mt-2">
                        Data about you that is accessible to you by means of
                        fcmb.com, including your personal data, can become
                        subject to the legal systems and laws in force in the
                        country where the data is held, received or stored by
                        you or us. Such data can become subject to disclosure
                        pursuant to the laws of the country.
                      </p>
                      <p className="mt-2">
                        We may reveal your name and other personal data and
                        other monetary data about you at the request of
                        regulatory agency or in connection with an examination
                        of us as a bank. This information could be revealed to
                        internal and external attorneys or auditors, and to
                        others whom we are required to make such revelations.
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p className="text-[18px] font-bold">
                    Information Security and Retention
                  </p>
                  <p className="mt-2">
                    At FERN, a subsidiary of FCMB, we are fully committed to
                    protecting the information we collect. We maintain
                    administrative, technical, and physical controls to actively
                    safeguard the Personal Information you provide or we
                    collect. These controls are designed to protect against
                    loss, theft, unauthorized access, disclosure, copying,
                    misuse, or modification.
                  </p>
                  <p className="mt-2">
                    Our security measures actively include secure servers,
                    firewalls, data encryption, and restricted access granted
                    only to employees for fulfilling their job responsibilities.
                    When using a password for any of your accounts, it is
                    essential that you actively ensure its confidentiality and
                    refrain from sharing it with anyone.
                  </p>
                  <p className="mt-2">
                    We actively conduct our business in accordance with these
                    principles to actively ensure the confidentiality and
                    protection of your Personal Information. While transmitting
                    information online may not be entirely secure, we actively
                    take all reasonable steps to ensure the security and
                    protection of your Personal Information. We will only retain
                    personal information on our servers for as long as it is
                    actively necessary while providing services to you. In the
                    event you close your account, we actively store your
                    information on our servers to comply with regulatory
                    obligations and actively monitor, detect, and prevent fraud.
                    Any retention of your Personal Data is solely for such
                    length of time as may be required by law, regulation, and
                    the internal policies of FCMB, her members and/or
                    affiliates.
                  </p>
                  <ol className="flex mt-2 flex-col gap-2">
                    <li>
                      <p className="font-bold">
                        Data Protection on the Internet
                      </p>
                      <p className="mt-2">
                        At FERN, a subsidiary of FCMB we utilize encryption
                        innovation to ensure the transmission of data to or from
                        you by means of fcmb.com. For security reasons and to
                        protect the security of your information, access to
                        fcmb.com is restricted to authorized users only.
                        However, because information about you, your account
                        data and other transactions can be accessed through a
                        public network, the Internet, there can be no guarantee
                        that your account information will remain secure and you
                        accept the risk that unauthorized persons may view such
                        information. If you believe that an unauthorized person
                        has accessed your information, please contact the Bank
                        immediately.
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p className="text-[18px] font-bold">
                    Updates to this Policy
                  </p>
                  <p className="mt-2">
                    From time to time, we may change, amend or review this
                    Privacy Policy from time to time to reflect new services or
                    changes in our Privacy Policy and place any updates on this
                    page. All changes made will be posted on this page and where
                    changes will materially affect you, we will notify you of
                    this change by placing a notice online or via mail. If you
                    keep using our Services, you consent to all amendments of
                    this Privacy Policy.
                  </p>
                </li>
                <li>
                  <p className="text-[18px] font-bold">Contact us</p>
                  <p className="mt-2">
                    For issues relating to personal data, please contact us via
                    any of the below:
                  </p>
                  <p className="mt-2">
                    Corporate Address: Primrose Tower, 17A, Tinubu Street,
                    Marina, Lagos
                  </p>
                  <p className="mt-2">Telephone: 07003290000, 01-2798800</p>
                  <p className="mt-2">
                    Email:{" "}
                    <a
                      className="text-[blue]"
                      href="mailto:customerservice@fcmb.com"
                    >
                      customerservice@fcmb.com
                    </a>
                  </p>
                  <p className="mt-2">
                    Whatsapp: (+234) 0700 329 0000 or (+234) 0700 329 0000
                  </p>
                </li>
              </ol>
            </Stack>
          </Box>
        </Inner>
      </section>
      <Footer />
    </Box>
  );
};

export default PrivacyPolicy;
