const AppPageHeader = ({ title, secondary }) => {
  return (
    <div className="w-full flex justify-between flex-wrap gap-3">
      <h1 className="pageHeader capitalize">{title}</h1>
      {secondary}
    </div>
  );
};

export default AppPageHeader;
