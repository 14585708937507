import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import UploadIcon from "@mui/icons-material/Upload";

import DisputeModal from "../../../../components/modals/table modals/admin/DisputeModal";
import TableLayout, { scrollToTableTop } from "../../../../layouts/tables/TableLayout";
import CustomPagination from "../../../../components/tables/CustomPagination";
import DashboardLayout from "../../../../layouts/dashboard/DashboardLayout";
import AppPageHeader from "../../../../components/shared/AppPageHeader";
import DisputeTable from "../../../../components/tables/DisputeTable";
import useDispute from "../../../../services/hooks/useDispute";

const GenericDispute = () => {
  const { type } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [searchParams, setSearchParams] = useState({});
  const { fetchAll, allDisputes, allError, isLoadingAll } = useDispute();
  const { userType, merchantCode } = useSelector((state) => state?.userAuth?.data);

  const isStaff = userType?.toLowerCase() === "staff";

  const handleSearch = (payload) => {
    setSearchParams(payload);
    fetchAll(payload);
  };

  const paginationHandler = (prop) => {
    scrollToTableTop();
    fetchAll({ ...searchParams, pageNumber: prop });
  };

  useEffect(() => {
    let params;
    if (isStaff) {
      params = { status: type };
    } else {
      params = { status: type, merchantCode };
    }
    fetchAll(params);
    setSearchParams(params);
  }, [fetchAll, isStaff, merchantCode, type]);

  return (
    <DashboardLayout>
      <div className="hide-scroll">
        <AppPageHeader
          title={`${type} Disputes`}
          secondary={
            isStaff ? (
              <Button variant="outlined" startIcon={<UploadIcon />} onClick={() => setIsOpen(true)}>
                Upload Dispute
              </Button>
            ) : null
          }
        />
        <div className="flex flex-col gap-[50px] mb-[40px] mt-[32px]">
          <TableLayout isLoading={isLoadingAll}>
            <DisputeTable
              action
              tab={type}
              error={allError}
              title={`All ${type} cases`}
              tableData={allDisputes?.data}
              onRefresh={() => fetchAll(searchParams)}
              onSearch={handleSearch}
              searchParams={searchParams}
              count={allDisputes?.totalRecords}
            />
            {allDisputes?.totalPages > 1 && (
              <CustomPagination
                paginationHandler={paginationHandler}
                totalPages={allDisputes?.totalPages}
                page={allDisputes?.currentPageNumber}
              />
            )}
          </TableLayout>
        </div>
      </div>
      {isStaff && (
        <DisputeModal
          open={isOpen}
          onClose={() => setIsOpen(false)}
          reload={() => fetchAll(searchParams)}
        />
      )}
    </DashboardLayout>
  );
};

export default GenericDispute;
