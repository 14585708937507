import "./index.css";
import { useSelector } from "react-redux";

import DashboardLayout from "../../../layouts/dashboard/DashboardLayout";
import AdminMerchantChecker from "./AdminMerchantChecker";
import AdminMerchantMaker from "./AdminMerchantMaker";

const Merchants = () => {
  const userRole = useSelector(
    (state) => state?.userAuth?.data?.role
  )?.toLowerCase();
  return (
    <DashboardLayout>
      {userRole && userRole === "checker" ? (
        <AdminMerchantChecker />
      ) : (
        <AdminMerchantMaker />
      )}
    </DashboardLayout>
  );
};

export default Merchants;
