import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import CheckerStatus from "./status/CheckerStatus";

const ApprovedRequests = () => {
  const userRole = useSelector(
    (state) => state?.userAuth?.data?.role
  )?.toLowerCase();
  return userRole === "checker" ? (
    <CheckerStatus
      status="approved"
      action={false}
      title="Approved Requests"
      search={true}
    />
  ) : (
    <Navigate to="/not-found" replace />
  );
};

export default ApprovedRequests;
