import "./index.css";
import { Box } from "@mui/material";

import DescriptionIcon from "@mui/icons-material/Description";
import Spinner from "../../../../utils/Spinner";

const SummaryCard = ({ color, title, count, isLoading }) => {
  return (
    <div className="summary-card-container w-full min-h-[125px] relative bg-[white] rounded-[10px] box-border p-3 border border-[#F0F0F0]">
      {isLoading && <Spinner />}
      <div className="summary-card-inner rounded-[5px] relative h-full box-border p-[15px] w-full flex flex-col justify-between border border-[#e7e7e7]">
        <div className="summary-heading w-full flex items-center justify-between">
          <Box component="p" color={color} className="title text-sm font-medium">
            {title}
          </Box>
          <DescriptionIcon sx={{ fontSize: "20px", color }} />
        </div>
        <p className="amount text-black-100 font-bold text-xl opacity-90">
          {count ? Number(count)?.toLocaleString() : 0}
        </p>
      </div>
    </div>
  );
};

export default SummaryCard;
