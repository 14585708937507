import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { downloadSampleFile } from "../../../utils/helperFunctions";
import sample from "../../../assets/sample/react_native_sample.txt";
import CodeSnippet from "../CodeSnippet";
import DocxTable from "../DocxTable";

const AcceptPayment = () => {
  return (
    <>
      <h1 className="section-header">Accepting Payment</h1>
      <p>Learn how to receive fast and secure payments with FERN Payment gateway.</p>
      <h1 className="section-sub-header mt-5">Integrating to websites:</h1>
      <ol style={{ listStyle: "decimal" }} className="flex flex-col gap-[10px]">
        <li className="mb-[10px]">Attach the payment gateway script to your website</li>
        <CodeSnippet canCopy language="HTML">
          {`<script src="${process.env.REACT_APP_PAYMENT_GATEWAY_SCRIPT_URL}"/>`}
        </CodeSnippet>
        <li className="my-[10px]">
          Setup your payment action to call a function which would handle your payment
        </li>
        <CodeSnippet language="HTML">
          {'<button onclick="payFunction()">Pay Now</button>'}
        </CodeSnippet>
        <li className="my-[10px]">setup your function to call the payment gateway function.</li>
        <CodeSnippet canCopy language="javascript">
          {`function payFunction() { fernGatewayPlugin.render(payload) }

//for javascript frameworks
function payFunction() { window.fernGatewayPlugin.render(payload) }
          `}
        </CodeSnippet>
      </ol>
      <p className="my-2">The payment gateway requires an object of certain key value parameters</p>
      <CodeSnippet language="javascript" canCopy>
        {`const payload = { 
    merchantCode: merchantCode.value, 
    publicKey: publicKey.value, 
    onSuccess: onSuccess, 
    onFailure: onFailure 
};`}
      </CodeSnippet>
      <p>The table below shows the list of parameters;</p>
      <DocxTable rows={rows} headers={headers} />
      <h1 className="section-sub-header mt-10">Integrating to mobile apps:</h1>
      <ol style={{ listStyle: "decimal" }} className="flex flex-col gap-[10px]">
        <li className="mb-[10px]">This integration works with webview and the source url is</li>
        <CodeSnippet canCopy>{`${process.env.REACT_APP_PAYMENT_GATEWAY_APP_URL}`}</CodeSnippet>
        <li className="my-[10px]">
          The document provided below is a sample integration file that will guide on how to
          integrate and some sample functions possibly needed are provided.
        </li>
        <div
          className="flex cursor-pointer items-center gap-2 w-fit p-3 pr-10 border border-[#cccccc] bg-[#f1f1f1] rounded"
          onClick={() => downloadSampleFile(sample, "Fern Gateway ReactNative Sample Integration")}
        >
          <InsertDriveFileIcon fontSize="large" color="disabled" />
          <div className="flex flex-col">
            <p className="font-medium text-black opacity-80">Fern Gateway Sample Integration</p>
            <span className="text-black opacity-50 text-xs tracking-wide">click to download</span>
          </div>
        </div>
      </ol>
    </>
  );
};

export default AcceptPayment;

const rows = [
  {
    name: "publicKey",
    desc: "your personal public key found in your portal",
    type: "string",
    required: "required",
  },
  {
    name: "merchantCode",
    desc: " your personal merchant code found in your portal",
    type: "string",
    required: "required",
  },
  {
    name: "amount",
    desc: "amount being charged",
    type: "number",
    required: "required",
  },
  {
    name: "email",
    desc: "email of the customer",
    type: "string",
    required: "required",
  },
  {
    name: "onSuccess",
    desc: (
      <p>
        your function to be called on successful transaction(for all payment options asides pay with
        card). this function receives an object property that holds the payment status and
        transaction reference. {`{ status:'enum', transactionRef:'string value' }`}. The value of
        status will be 'Success'
      </p>
    ),
    type: "function",
    required: "required",
  },
  {
    name: "onFailure",
    desc: (
      <p>
        your function to be called on failed transaction(for all payment options asides pay with
        card) and also when the gateway is forcefully closed. this function receives an object
        property that holds the payment status and transaction reference.{" "}
        {`{ status:'enum', transactionRef:'string value' }`}. The value of status will be one of;
        <br />
        <span className="inline-block mt-1">
          'NoAction': if the gateway was closed before payment was initiated
          <br />
          'Failed': if the transaction could not be completed
        </span>
      </p>
    ),
    type: "function",
    required: "required",
  },
  {
    name: "orderId",
    desc: "unique identification for the order",
    type: "string",
    required: "required",
  },
  {
    name: "customerName",
    desc: "name of the customer making payment",
    type: "string",
    required: "optional",
  },
  {
    name: "paymentOption",
    desc: "control the payment options you offer e.g { card: true, payWithBank: false, payLater: false, USSD: false }. if ommitted, all options will be enabled",
    type: "object",
    required: "optional",
  },
];

const headers = ["Key Name", "Description", "Type", "isRequired"];
