import { useEffect, useMemo } from "react";
import {
  matchRoutes,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";

import AdminTab from "../../../components/staff/AdminTab";

const InnerReportsLayout = () => {
  const navigate = useNavigate();
  const nameUrl = useLocation();
  const route = useMemo(() => [{ path: "/reports" }], []);

  const links = [
    {
      linkName: "Merchant Report",
      path: "merchants",
    },
    {
      linkName: "Transaction Report",
      path: "transactions",
    },
  ];

  useEffect(() => {
    if (matchRoutes(route, nameUrl)) {
      navigate("merchants", { replace: true });
    }
  }, [nameUrl, navigate, route]);

  return (
    <div className="admin-reports">
      <AdminTab links={links} />
      <Outlet />
    </div>
  );
};

export default InnerReportsLayout;
