import moment from "moment";
import { IconButton } from "@mui/material";

import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import useDispute from "../../../../services/hooks/useDispute";
import { useSelector } from "react-redux";

const FileCard = ({ file, disputeId, onDelete }) => {
  const { deleteFile, isFiling } = useDispute();
  const { userType } = useSelector((state) => state?.userAuth?.data);
  const isStaff = userType?.toLowerCase() === "staff";

  const handleDelete = async () => {
    const res = await deleteFile(disputeId, file.id);
    if (res) {
      onDelete(disputeId);
    }
  };

  return (
    <div
      className={`flex w-full items-center gap-2 ${
        isFiling ? "opacity-50 pointer-events-none" : ""
      }`}
    >
      <a
        href={file.uri}
        target="_blank"
        rel="noreferrer"
        className="flex flex-grow items-center justify-between gap-4 bg-[#F8F8FA] p-2 rounded"
      >
        <div className="flex items-center gap-2">
          <InsertDriveFileIcon color="primary" />
          <div className="flex flex-col w-full">
            <span className="text-xs text-grey">
              {moment(file.dateCreated).format("DD MMM YYYY")}
            </span>
            <p className="text-black">{file.name}</p>
          </div>
        </div>
        <p className="text-sm text-grey">{file.type?.toLowerCase()}</p>
      </a>
      {isStaff && (
        <IconButton aria-label="delete" color="error" onClick={handleDelete}>
          <RemoveCircleOutlineIcon />
        </IconButton>
      )}
    </div>
  );
};

export default FileCard;
