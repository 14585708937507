import { useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Alert, Box, Breadcrumbs, Typography } from "@mui/material";

import AppLabelValue from "../../../components/shared/AppLabelValue";
import { paymentChannel, paymentStatus } from "../../../utils/constants";
import DashboardLayout from "../../../layouts/dashboard/DashboardLayout";
import { alertError, numberWithCommas } from "../../../utils/helperFunctions";
import { singleTransaction } from "../../../services/actions/transactions.actions";
import TransactionDetailsLayout from "../../../layouts/dashboard/transactionDetails/TransactionDetailsLayout";

const TransactionDetails = () => {
  const params = useParams();
  const location = useLocation();
  const [isLoading, setisLoading] = useState(false);
  const [info, setInfo] = useState(location?.state || {});
  const merchantId = useSelector((state) => state?.userAuth?.data?.merchantCode)?.toLowerCase();

  const transactionId = params.id;

  const fetchTransaction = useCallback(async () => {
    setisLoading(true);
    try {
      const response = await singleTransaction(merchantId, params.id);
      if (response) {
        setInfo(response.data[0]);
      }
    } catch (error) {
      alertError(error);
    } finally {
      setisLoading(false);
    }
  }, [merchantId, params.id]);

  useEffect(() => {
    if (!location.state) {
      fetchTransaction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchTransaction]);

  return (
    <DashboardLayout>
      <Box className='transaction-container'>
        <Breadcrumbs
          separator='>'
          sx={{
            fontSize: "14px",
            fontFamily: "General Sans",
            letterSpacing: "0.02em",
          }}
        >
          <Link underline='hover' color='inherit' to='/transactions'>
            Transactions
          </Link>
          <Typography
            color='primary'
            sx={{
              fontSize: "14px",
              fontFamily: "General Sans",
              fontWeight: 500,
            }}
          >
            {transactionId?.toUpperCase()}
          </Typography>
        </Breadcrumbs>
        <div className='transaction-details'>
          <Box className='payment-details'>
            <TransactionDetailsLayout isLoading={isLoading} title='Payment Details'>
              <div className='details-breakdown'>
                <AppLabelValue label='Customer Name' valueClass='capitalize' value={info?.customerName?.toLowerCase()} />

                <AppLabelValue label='Customer Email' value={info?.customerEmail?.toLowerCase()} />
                {info.currency && (
                  <AppLabelValue label='Currency' value={info?.currency?.toUpperCase()} />
                )}

                <AppLabelValue label='Amount' value={numberWithCommas(info?.amount)} />

                <AppLabelValue label='Payment Method' value={paymentChannel?.[info?.channel]} />

                <AppLabelValue label='Transaction Date/Time' value={info?.transactionDate} />

                <AppLabelValue label='Issuing Bank' valueClass='capitalize' value={info?.bank?.toLowerCase()} />

                <AppLabelValue label='Issuing Country' valueClass='capitalize' value={info?.country?.toLowerCase()} />
              </div>
            </TransactionDetailsLayout>
          </Box>
          <Box className='transaction-status'>
            <TransactionDetailsLayout isLoading={isLoading} title='Transaction Status'>
              <Alert
                severity={
                  paymentStatus?.[info?.status]?.toLowerCase()?.includes("success")
                    ? "success"
                    : paymentStatus?.[info?.status]?.toLowerCase()?.includes("pending")
                    ? "warning"
                    : "error"
                }
                icon={false}
                sx={{ justifyContent: "center" }}
              >
                {paymentStatus?.[info?.status]}
              </Alert>
              <div className='w-full grid grid-cols-2'>
                <AppLabelValue label='Refund' value={"-"} />

                <AppLabelValue label='chargeback' value={numberWithCommas(info?.amount)} />
              </div>
            </TransactionDetailsLayout>
          </Box>
        </div>
      </Box>
    </DashboardLayout>
  );
};

export default TransactionDetails;
