import { Box } from "@mui/material";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { alertError } from "../../../../utils/helperFunctions";
import TableLayout from "../../../../layouts/tables/TableLayout";
import NoTableData from "../../../../components/tables/NoTableData";
import TransactionCard from "../../../../components/merchant/TransactionCard";
import TransactionsTable from "../../../../components/tables/TransactionsTable";
import { merchantTransactionsOverview } from "../../../../services/actions/transactions.actions";

const MerchantOverview = () => {
  const { merchantCode, mode } = useSelector((state) => state?.userAuth?.data);
  const [successful, setSuccessful] = useState("0");
  const [isLoading, setisLoading] = useState("0");
  const [tableRows, setTableRows] = useState([]);
  const [failed, setFailed] = useState("0");

  const merchantId = merchantCode?.toLowerCase();
  const fetchTransactions = useCallback(async () => {
    setisLoading(true);
    try {
      const response = await merchantTransactionsOverview(merchantId);
      if (response) {
        setTableRows(response?.transactions?.splice(0, 10) || []);
        setFailed(response?.failed || "0");
        setSuccessful(response?.successful || "0");
      }
    } catch (error) {
      alertError(error);
    } finally {
      setisLoading(false);
    }
  }, [merchantId]);

  useEffect(() => {
    fetchTransactions();
  }, [fetchTransactions, mode]);

  return (
    <>
      <div className="overview-container">
        <h1 className="page-title">Overview</h1>
        <Box className="transactions-overview">
          <TransactionCard
            isLoading={isLoading}
            title="Successful Transactions"
            amount={successful}
          />
          <TransactionCard isLoading={isLoading} title="Failed Transactions" amount={failed} />
        </Box>
        <TableLayout isLoading={isLoading}>
          {tableRows?.length < 1 && <NoTableData />}
          {tableRows?.length > 0 && <TransactionsTable recent={true} tableRows={tableRows} />}
        </TableLayout>
      </div>
    </>
  );
};

export default MerchantOverview;
