import "./index.css";
import { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";

import InfoIcon from "@mui/icons-material/Info";

import LogoutModal from "../../../components/modals/logout/LogoutModal";

import useLogout from "../../../services/hooks/useLogout";
import { ICONS, IMAGES } from "../../../assets";
import GoLiveModal from "../../../components/modals/goLive/GoLiveModal";
import { dashboardNav } from "../../../routes/AppRoutes";

const LayoutMenu = ({ isOpen, toggleNav }) => {
  const navigate = useNavigate();
  const logoutUser = useLogout();
  const mode = useSelector(
    (state) => state?.userAuth?.data?.mode
  )?.toLowerCase();
  const [open, setOpen] = useState({ logout: false, golive: false });
  const userType = useSelector(
    (state) => state?.userAuth?.data?.userType
  )?.toLowerCase();
  const role = useSelector((state) =>
    state?.userAuth?.data?.role?.toLowerCase()
  );

  const isStaff = userType === "staff";

  const { LogOutIcon } = ICONS;

  const openDialog = (prop) => {
    setOpen((p) => ({ ...p, [prop]: true }));
  };

  const closeDialog = (prop) => {
    setOpen((p) => ({ ...p, [prop]: false }));
  };

  const confirmLogout = () => {
    logoutUser();
    closeDialog("logout");
  };

  const generateNav = () => {
    const nav = dashboardNav?.filter(
      (item) => item.type.includes(userType) && item.role.includes(role)
    );
    return nav;
  };

  return (
    <>
      <LogoutModal
        open={open.logout}
        closeDialog={() => closeDialog("logout")}
        confirmAction={confirmLogout}
      />
      <GoLiveModal
        open={open.golive}
        closeDialog={() => closeDialog("golive")}
      />
      <aside
        className={`layout-menu xl:z-30 w-[256px] max-w-full h-screen flex flex-col gap-[60px] bg-[#F8F8F9] pl-6 pt-4 ${
          isOpen ? "mobile-open" : ""
        }`}
      >
        <div className="app-brand">
          <div
            className="app-brand cursor-pointer"
            onClick={() => {
              navigate(`/${userType}/overview`);
            }}
          >
            <img
              src={IMAGES.DashboardLogo}
              alt="FERN logo and payment gateway text"
            />
          </div>
          <div
            className="layout-menu-toggle cursor-pointer border-[2px] bg-purple border-purple"
            onClick={toggleNav}
            aria-label="button"
          >
            <img
              src={ICONS.angleDown}
              alt="arrow pointing left"
              className="toggle-arrow text-white"
            />
          </div>
        </div>
        <nav className="flex-grow hide-scroll">
          <ul className="menu-container list-none w-full h-full relative">
            {generateNav()?.map((route, index) => (
              <div className="mb-[15px]" key={index}>
                <NavLink
                  to={route?.path}
                  className={`menu-item font-[600] flex items-center ${
                    route.dropdown ? "mb-[7px]" : "mb-0"
                  }`}
                  onClick={toggleNav}
                >
                  {route?.icon}
                  <span>{route?.name}</span>
                </NavLink>
                {route.dropdown ? (
                  <div className="flex flex-col ml-5">
                    {route?.dropdown?.map((sub, i) => (
                      <NavLink
                        key={i}
                        to={sub?.path}
                        className={
                          "menu-item mb-[5px] font-[500] !py-[7px] flex items-center"
                        }
                        onClick={toggleNav}
                      >
                        <span>{sub?.name}</span>
                      </NavLink>
                    ))}
                  </div>
                ) : null}
              </div>
            ))}
            <div className="absolute bottom-[20px]">
              {!isStaff && mode === "test" ? (
                <div
                  className="menu-item flex font-[500] mb-3 items-center go-live"
                  onClick={() => openDialog("golive")}
                >
                  <InfoIcon color="primary" />
                  <span>How to go live</span>
                </div>
              ) : null}
              <div
                className="menu-item flex font-[500] items-center sign-out"
                onClick={() => openDialog("logout")}
              >
                <LogOutIcon />
                <span>Sign Out</span>
              </div>
            </div>
          </ul>
        </nav>
      </aside>
    </>
  );
};

export default LayoutMenu;
