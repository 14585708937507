import { useState } from "react";
import { useSelector } from "react-redux";
import { Box, Button, Modal, TextField } from "@mui/material";

import Spinner from "../../../../utils/Spinner";
import SuccessModalContent from "./SuccessModalContent";
import { alertError } from "../../../../utils/helperFunctions";
import { sendMessage } from "../../../../services/actions/staffMakerChecker.actions";

const SendMessageModal = ({ open, handleClose, type, merchant, reload }) => {
  const [step, setStep] = useState(1);
  const [message, setMessage] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const sender = useSelector((state) => state?.userAuth?.data);

  const isChecker = sender?.role?.toLowerCase() === "checker";
  const checker = merchant?.supervisor || "Everyone";
  const maker = merchant?.createdBy;

  const closeModal = () => {
    setMessage("");
    setStep(1);
    reload();
    handleClose();
  };

  const handleChange = (e) => {
    const input = e.target.value;
    setMessage(input);
  };

  const sendMessageHandler = async () => {
    setisLoading(true);

    const payload = {
      comment: message,
      senderRole: sender.role,
      senderId: sender.staffId,
      receiver: isChecker ? maker : checker,
    };

    try {
      await sendMessage(merchant?.merchantCode, payload);
      setStep(2);
    } catch (error) {
      alertError(error);
    } finally {
      setisLoading(false);
    }
  };

  let textLength = 96 - message.length > 0 ? 96 - message.length : 0;

  return (
    <div>
      <Modal open={open} onClose={closeModal}>
        <Box className="modal-content">
          {step && step === 1 && (
            <div className="font-medium">
              {isLoading && <Spinner size={24} color="secondary" />}
              <h3 className="font-medium text-base pb-2 border-b border-[#E8E6E9]">
                {type === "message" ? "Send Message" : "Approve with Comment"}
              </h3>
              {type === "message" && (
                <p className="text-sm mt-4">
                  To: <span className="text-purple">{isChecker ? maker : checker}</span>
                </p>
              )}
              <p className="text-sm mt-[37px] mb-4">
                {type === "message" ? "Enter Message" : "Comment"}
              </p>
              <Box component="form" autoComplete="off">
                <TextField
                  name="message"
                  id="message"
                  className="input-section"
                  fullWidth
                  required
                  multiline
                  rows={5}
                  type="text"
                  inputProps={{
                    maxLength: 96,
                  }}
                  value={message}
                  onChange={handleChange}
                  helperText={
                    <span className="block text-end">{`${textLength} characters left`}</span>
                  }
                />
              </Box>

              <div className="flex gap-4 mt-[2rem]">
                <Button
                  variant="outlined"
                  sx={{
                    height: "56px",
                    fontSize: "16px",
                    boxShadow: "none",
                    maxWidth: "100%",
                    width: "200px",
                  }}
                  onClick={closeModal}
                >
                  Cancel
                </Button>
                <Button
                  disableElevation
                  variant="contained"
                  sx={{
                    height: "56px",
                    fontSize: "16px",
                    boxShadow: "none",
                    maxWidth: "100%",
                    width: "200px",
                  }}
                  disabled={message.length < 2}
                  onClick={sendMessageHandler}
                >
                  Submit
                </Button>
              </div>
            </div>
          )}
          {step && step === 2 && (
            <SuccessModalContent
              closeModal={closeModal}
              header={type === "message" ? "Message sent" : "Submission successful!"}
              actionText="Done"
            />
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default SendMessageModal;
