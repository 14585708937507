import { useState } from "react";
import { CopyAll, DoneAll } from "@mui/icons-material";
import { IconButton, TextField } from "@mui/material";

const AppCopyInput = ({ name, label, value, type = "text", id, ...r }) => {
  const [isCopied, setIsCopied] = useState(false);
  const handleCopyPassword = () => {
    navigator.clipboard.writeText(value || "");
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 5000);
  };
  return (
    <div className="flex items-start">
      <TextField
        name={name}
        label={label}
        id={id}
        className="input-section"
        value={value}
        type={type}
        sx={{ ".MuiInputBase-root": { borderRadius: "3px 0px 0px 3px" } }}
        {...r}
      />
      {type !== "password" ? (
        <IconButton
          aria-label="copy"
          sx={{
            borderRadius: "0px 3px 3px 0px",
            background: "#5C068C",
            color: "white",
            "&:hover": { background: "inheret", color: "#5C068C" },
          }}
          color="primary"
          onClick={handleCopyPassword}
          disabled={isCopied}
        >
          {isCopied ? <DoneAll /> : <CopyAll />}
        </IconButton>
      ) : (
        <IconButton disabled sx={{ "&:disabled": { color: "white" } }}>
          <DoneAll />
        </IconButton>
      )}
    </div>
  );
};

export default AppCopyInput;
