import "react-toastify/dist/ReactToastify.css";
import { AxiosInterceptor } from "../config/Axios";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

import ScrollToTop from "../utils/ScrollToTop";
import Notification from "../utils/Notification";

import Store from "../pages/demo store/Store";
import ErrorPage from "../pages/error/ErrorPage";
import DocxPage from "../pages/documentation/DocxPage";
import LoginPage from "../pages/authentication/LoginPage";

import ResetPassword from "../pages/authentication/ResetPassword";
import ForgotPassword from "../pages/authentication/ForgotPassword";
import TermsAndConditions from "../pages/others/TermsAndConditions";
import ChangePassword from "../pages/authentication/ChangePassword";
import PrivacyPolicy from "../pages/others/PrivacyPolicy";
import FirstTimeMerchant from "./FirstTimeMerchant";
import FirstLoginChecker from "./FirstLoginChecker";
import LandingPage from "../pages/landing page";
import UnAuthenticated from "./UnAuthenticated";
import AuthSuperAdmin from "./AuthSuperAdmin";
import Authenticated from "./Authenticated";
import AuthMerchants from "./AuthMerchants";
import AuthStaff from "./AuthStaff";
import AdminManageStaff from "../pages/dashboard/manage-staff/AdminManageStaff";
import AuthSystemUser from "./AuthSystemUser";
import Overview from "../pages/dashboard/overview/Overview";
import Transactions from "../pages/dashboard/transactions/Transactions";
import Income from "../pages/dashboard/income/Income";
import TransactionDetails from "../pages/dashboard/transactions/TransactionDetails";
import Reports from "../pages/dashboard/reports/Reports";
import MerchantReports from "../pages/dashboard/reports/MerchantReports";
import TransactionReports from "../pages/dashboard/reports/TransactionReports";
import Merchants from "../pages/dashboard/manage-merchants/Merchants";
import MerchantDetails from "../pages/dashboard/manage-merchants/MerchantDetails";
import Dispute from "../pages/dashboard/dispute/Dispute";
import DisputeDetails from "../pages/dashboard/dispute/details/DisputeDetails";
import GenericDispute from "../pages/dashboard/dispute/cases/Generic";
import Settings from "../pages/dashboard/settings/Settings";
import General from "../pages/dashboard/settings/tabs/General";
import Notifications from "../pages/dashboard/settings/tabs/Notifications";
import SwitchPassword from "../pages/dashboard/settings/tabs/merchant/SwitchPassword";
import Contact from "../pages/dashboard/settings/tabs/merchant/Contact";
import Channels from "../pages/dashboard/settings/tabs/merchant/Channels";
import Bank from "../pages/dashboard/settings/tabs/merchant/Bank";
import Users from "../pages/dashboard/settings/tabs/merchant/Users";
import ApiKeys from "../pages/dashboard/settings/tabs/merchant/ApiKeys";
import ApprovedRequests from "../pages/dashboard/manage-merchants/adminChecker/ApprovedRequests";
import DeclinedRequests from "../pages/dashboard/manage-merchants/adminChecker/DeclinedRequests";
import PendingApprovals from "../pages/dashboard/manage-merchants/adminChecker/PendingApprovals";
import MakerDeclined from "../pages/dashboard/manage-merchants/adminMaker/MakerDeclined";
import MakerPending from "../pages/dashboard/manage-merchants/adminMaker/MakerPending";
import MakerReferred from "../pages/dashboard/manage-merchants/adminMaker/MakerReferred";
import AllMerchants from "../pages/dashboard/manage-merchants/AllMerchants";
import MerchantDeletion from "../pages/dashboard/manage-merchants/adminChecker/MerchantDeletion";
import AuthAdminMerchant from "./AuthAdminMerchant";
import SystemLockout from "../pages/dashboard/system-lockout";
import StandardHours from "../pages/dashboard/system-lockout/StandardHours";
import ExemptedUsers from "../pages/dashboard/system-lockout/ExemptedUsers";

const AppRoutes = () => {
  return (
    <Router>
      <AxiosInterceptor />
      <ScrollToTop />
      <Notification />
      <Routes>
        <Route path='/' element={<LandingPage />} />
        <Route path='/terms-and-conditions' element={<TermsAndConditions />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/documentation' element={<DocxPage />} />
        <Route path='/not-found' element={<ErrorPage />} />
        <Route path='/demo-store' element={<Store />} />
        <Route element={<Authenticated />}>
          <Route element={<FirstTimeMerchant />}>
            <Route path='/change-password' element={<ChangePassword />} />
          </Route>
          <Route element={<FirstLoginChecker />}>
            <Route element={<AuthSuperAdmin />}>
              <Route path='/manage-staff' element={<AdminManageStaff />} />
              <Route path='/system-lockout' element={<SystemLockout />}>
                <Route path='standard' element={<StandardHours />} />
                <Route path='exemption' element={<ExemptedUsers />} />
              </Route>
            </Route>
            <Route element={<AuthSystemUser />}>
              <Route path='/overview' element={<Overview />} />
              <Route path='/overview/:currentView' element={<Overview />} />
              <Route path='/transactions' element={<Transactions />} />
              <Route path='/transactions/:id' element={<TransactionDetails />} />
              <Route element={<AuthMerchants />}>
                <Route path='/income' element={<Income />} />
              </Route>
              <Route element={<AuthStaff />}>
                <Route path='/merchants' element={<Merchants />}>
                  <Route index element={<AllMerchants />} />
                  <Route path='view' element={<AllMerchants />} />
                  <Route path='pending' element={<MakerPending />} />
                  <Route path='declined' element={<MakerDeclined />} />
                  <Route path='referred' element={<MakerReferred />} />
                  <Route path='pending-approvals' element={<PendingApprovals />} />
                  <Route path='approved' element={<ApprovedRequests />} />
                  <Route path='declined-requests' element={<DeclinedRequests />} />
                  <Route path='deletion' element={<MerchantDeletion />} />
                </Route>
                <Route path='/merchants/:page/details/:id' element={<MerchantDetails />} />
                <Route path='/reports' element={<Reports />}>
                  <Route index element={<MerchantReports />} />
                  <Route path='merchants' element={<MerchantReports />} />
                  <Route path='transactions' element={<TransactionReports />} />
                </Route>
              </Route>
              <Route path='/disputes' element={<Dispute />} />
              <Route path='/disputes/:type' element={<GenericDispute />} />
              <Route path='/disputes/:tab/details/:id' element={<DisputeDetails />} />
              <Route path='/settings' element={<Settings />}>
                <Route index element={<General />} />
                <Route path='general' element={<General />} />
                <Route path='notifications' element={<Notifications />} />
                <Route element={<AuthMerchants />}>
                  <Route path='change-password' element={<SwitchPassword />} />
                  <Route path='contact' element={<Contact />} />
                  <Route element={<AuthAdminMerchant />}>
                    <Route path='channels' element={<Channels />} />
                    <Route path='bank' element={<Bank />} />
                    <Route path='users' element={<Users />} />
                    <Route path='keys' element={<ApiKeys />} />
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>
        <Route element={<UnAuthenticated />}>
          <Route path='/login' element={<LoginPage />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/reset-password' element={<ResetPassword />} />
        </Route>
        <Route path='*' element={<Navigate to='/not-found' replace />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
