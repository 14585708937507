import "./index.css";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useCallback, useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import Spinner from "../../../../../utils/Spinner";
import { alertError } from "../../../../../utils/helperFunctions";
import { Box, Button, IconButton, InputAdornment } from "@mui/material";
import AppCopyInput from "../../../../../components/shared/AppCopyInput";
import { fetchToken } from "../../../../../services/actions/merchant.actions";
import DisableLiveModal from "../../../../../components/modals/disableLive/DisableLiveModal";

const ApiKeys = () => {
  const { merchantCode, mode } = useSelector((state) => state?.userAuth?.data);
  const [keys, setKeys] = useState({
    publicKey: "",
    secretKey: "",
    testPublicKey: "",
    testPrivateKey: "",
  });
  const [showKey, setShowKey] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleMouseDown = (e) => {
    e.preventDefault();
  };

  const fetchTokenHandler = useCallback(async () => {
    setIsLoading(true);
    if (mode) {
      try {
        const response = await fetchToken(merchantCode);
        if (response) {
          setKeys(response);
        }
      } catch (error) {
        alertError(error);
      } finally {
        setIsLoading(false);
      }
    }
  }, [merchantCode, mode]);

  useEffect(() => {
    fetchTokenHandler();
  }, [fetchTokenHandler]);

  return (
    <>
      <Box className="api-settings-container">
        <h1 className="title">API Keys Settings</h1>
        <form className="settings-form relative py-2">
          {isLoading && <Spinner />}
          <div className="form-fields">
            <AppCopyInput
              name="merchantCode"
              label="Merchant Code"
              id="merchantCode"
              className="input-section"
              value={merchantCode}
              fullWidth
              disabled
            />
            {mode?.toLowerCase() === "test" ? (
              <>
                <AppCopyInput
                  name="testPublicKey"
                  label="Test Public Key"
                  id="testPublicKey"
                  className="input-section"
                  value={keys.testPublicKey}
                  fullWidth
                  disabled
                />
                <AppCopyInput
                  name="testPrivateKey"
                  label="Test Secret Key"
                  id="testPrivateKey"
                  className="input-section"
                  value={keys.testPrivateKey}
                  fullWidth
                  disabled
                />
              </>
            ) : (
              <>
                <AppCopyInput
                  name="livePublicKey"
                  label="Live Public Key"
                  id="livePublicKey"
                  className="input-section"
                  value={keys.publicKey}
                  fullWidth
                  disabled
                />
                <AppCopyInput
                  name="liveSecretKey"
                  label="Live Secret Key"
                  id="liveSecretKey"
                  className="input-section"
                  fullWidth
                  required
                  disabled
                  type={showKey ? "text" : "password"}
                  value={keys.secretKey}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowKey(!showKey)}
                          onMouseDown={handleMouseDown}
                          edge="end"
                        >
                          {showKey ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {mode?.toLowerCase() === "live" ? (
                  <Button
                    className="save-changes-btn"
                    color="primary"
                    variant="contained"
                    disableElevation
                    onClick={() => setShowModal(true)}
                  >
                    Disable Live
                  </Button>
                ) : null}
              </>
            )}
          </div>
        </form>
      </Box>
      <DisableLiveModal open={showModal} onClose={() => setShowModal(false)} />
    </>
  );
};

export default ApiKeys;
