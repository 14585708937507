import { useSelector } from "react-redux";
import DashboardLayout from "../../../layouts/dashboard/DashboardLayout";
import AdminSettings from "./AdminSettings";
import MerchantSettings from "./MerchantSettings";

const Settings = () => {
  const { userType } = useSelector((state) => state?.userAuth?.data);
  const isMerchant = userType?.toLowerCase()?.includes("merchant");
  return (
    <DashboardLayout>
      {isMerchant ? <MerchantSettings /> : <AdminSettings />}
    </DashboardLayout>
  );
};

export default Settings;
