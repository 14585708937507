import { useState } from "react";

import {
  alertError,
  convertBase64,
  isFileSizeValid,
  isFileTypeValid,
} from "../../utils/helperFunctions";
import Spinner from "../../utils/Spinner";

const AppUploader = ({
  onSelect,
  fileTypes = ["pdf", "jpg", "jpeg", "png"],
  isBase64,
  isLoading,
  isError = false,
}) => {
  const [file, setFile] = useState(null);

  const handleFileChange = async (e) => {
    try {
      const file = e.currentTarget.files;

      if (file) {
        if (!isFileTypeValid(file[0].name, fileTypes))
          throw new Error(`Invalid file type. File should be ${fileTypes?.join()}`);

        if (!isFileSizeValid(file[0].size))
          throw new Error("File should be lesser than or equal to 10MB");

        if (isBase64) {
          const base64 = await convertBase64(file[0]);
          onSelect(base64);
        } else {
          onSelect(file[0]);
        }
        setFile(file[0]);
      }
    } catch (error) {
      alertError(error);
    }
  };

  const handleRemove = () => {
    setFile(null);
    onSelect(null);
  };

  return (
    <div
      className={`w-full relative h-full box-border border border-dashed flex flex-col justify-center items-center ${
        isError ? "border-alert" : "border-purple"
      }`}
    >
      {isLoading && <Spinner size={30} />}
      <input
        type="file"
        required
        onChange={handleFileChange}
        className={`w-full h-full cursor-pointer opacity-0 absolute top-0 left-0 ${
          file ? "pointer-events-none" : "z-10"
        }`}
      />
      {!file && (
        <div className="flex flex-col items-center gap-1 justify-center">
          <p className="text-black opacity-90 font-medium">Drag and Drop File Here</p>
          <p className="uppercase text-[10px] tracking-wider text-black opacity-40">
            click to upload
          </p>
        </div>
      )}
      {file && (
        <div className="flex flex-col items-center gap-1 justify-center">
          <p className="text-black opacity-90 font-medium text-center">{file.name}</p>
          <p
            className="uppercase cursor-pointer text-purple-20 text-[10px] tracking-wider"
            onClick={handleRemove}
          >
            remove
          </p>
        </div>
      )}
    </div>
  );
};

export default AppUploader;
