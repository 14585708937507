import { PRODUCTS } from "../../assets";

export const vat = 10;

export const tableHeaders = ["Product", "Price", "Quantity", "Subtotal", ""];

export const user = {
  name: "",
  email: "",
};

export const order = {
  subTotal: 0,
  total: 0,
  vat: 0,
  delivery: 0,
};

export const products = [
  {
    id: 1,
    name: "Futuristic smart watch",
    price: 5000,
    image: PRODUCTS.watch,
    sku: "476843",
  },
  {
    id: 2,
    name: "Red nike running shoes",
    price: 34000,
    image: PRODUCTS.nike,
    sku: "436823",
  },
  {
    id: 3,
    name: "Vintage HD camera",
    price: 250000,
    image: PRODUCTS.camera,
    sku: "437723",
  },
  {
    id: 4,
    name: "3 in 1 classic socks",
    price: 10,
    image: PRODUCTS.socks,
    sku: "436000",
  },
  {
    id: 5,
    name: "Soda",
    price: 100,
    image: PRODUCTS.drink,
    sku: "425823",
  },
  {
    id: 6,
    name: "Durable hikers backpack",
    price: 10000,
    image: PRODUCTS.backpack,
    sku: "436823",
  },
  {
    id: 7,
    name: "All black tinted sunglasses",
    price: 1000,
    image: PRODUCTS.glasses,
    sku: "336823",
  },
  {
    id: 8,
    name: "Noise blocker headphones",
    price: 500000,
    image: PRODUCTS.headphones,
    sku: "324723",
  },
];
