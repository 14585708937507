import { useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { Box, Button, LinearProgress, Stack } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";

import UsersModal from "../../../../../components/modals/table modals/merchant/UsersModal";
import { allSubMerchants } from "../../../../../services/actions/merchant.actions";
import UsersTable from "../../../../../components/tables/merchant/UsersTable";
import { alertError } from "../../../../../utils/helperFunctions";

const Users = () => {
  const merchantCode = useSelector((state) => state?.userAuth?.data?.merchantCode);
  const [open, setOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [tableRows, setTableRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const emptyUser = {
    fullName: "",
    emailAddress: "",
    role: "",
  };
  const [initialValues, setInitialValues] = useState(emptyUser);

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setModalTitle("");
    setInitialValues(emptyUser);
    setOpen(false);
  };

  const editUserHandler = (prop) => {
    setInitialValues(prop);
    setModalTitle("Edit " + prop.fullName);
    openModal();
  };

  const createUserHandler = () => {
    setInitialValues(emptyUser);
    setModalTitle("Invite New User");
    openModal();
  };

  const fetchAllUsers = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await allSubMerchants(merchantCode);
      if (response) {
        setTableRows(response);
      }
    } catch (error) {
      alertError(error);
    } finally {
      setIsLoading(false);
    }
  }, [merchantCode]);

  useEffect(() => {
    fetchAllUsers();
  }, [fetchAllUsers]);

  return (
    <>
      <UsersModal
        open={open}
        closeModal={closeModal}
        initialValues={initialValues}
        title={modalTitle}
        reload={() => fetchAllUsers()}
      />
      <Box className="users-settings-container">
        <div className="relative flex items-center justify-between pb-3">
          {isLoading && (
            <div className="w-full absolute bottom-0">
              <LinearProgress />
            </div>
          )}
          <h1 className="title">Account Users</h1>
          <Button
            className="invite-user-button"
            variant="contained"
            disableElevation
            onClick={createUserHandler}
            disabled={tableRows.length >= 5}
          >
            <Stack className="user-invite" gap="10px" alignItems="center" direction="row">
              <AddIcon />
              Invite New User
            </Stack>
          </Button>
        </div>
        {tableRows.length > 0 && (
          <UsersTable
            tableRows={tableRows}
            onEdit={editUserHandler}
            reload={() => fetchAllUsers()}
          />
        )}
      </Box>
    </>
  );
};

export default Users;
