import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const FirstTimeMerchant = () => {
  const { userFirstLogin, userType } = useSelector(
    (state) => state?.userAuth?.data
  );
  const isMerchant = userType?.toLowerCase()?.includes("merchant");

  return isMerchant && userFirstLogin ? (
    <Outlet />
  ) : (
    <Navigate to="/overview" />
  );
};

export default FirstTimeMerchant;
