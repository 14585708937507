import "../index.css";
import { useFormik } from "formik";
import { useState, useEffect } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import {
  adminCreateStaff,
  adminUpdateStaff,
  verifyStaffEmail,
} from "../../../../services/actions/staffAdmin.actions";
import ModalLayout from "../ModalLayout";
import { FCMBEmailStructure } from "../../../../utils/constants";
import { validateEmailSchema } from "../../../../utils/formikFormValidators";
import { alertError, alertSuccess } from "../../../../utils/helperFunctions";

const ADEmailVerify = process.env.REACT_APP_AD_VERIFY_STAFF_EMAIL;

const StaffModal = (props) => {
  const [values, setValues] = useState();
  const [isLoading, setisLoading] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);

  const isCreate = props.title.toLowerCase().includes("new");
  const isEdit = props.title.toLowerCase().includes("edit");
  const roles = ["SuperAdmin", "Maker", "Checker"];

  const inputChangeHandler = (prop) => (e) => {
    setValues({ ...values, [prop]: e.target.value });
  };

  const formik = useFormik({
    initialValues: {
      emailAddress: "",
    },
    validationSchema: validateEmailSchema,
    onSubmit: (values, { resetForm }) => {
      verifyEmailHandler(values);
      resetForm();
    },
  });

  const verifyEmailAPI = async (prop) => {
    setisLoading(true);
    const payload = prop;
    try {
      const data = await verifyStaffEmail(payload);
      if (data) {
        setValues({ ...values, ...data });
        setEmailVerified(true);
      }
    } catch (error) {
      alertError(error);
    } finally {
      setisLoading(false);
    }
  };

  const verifyEmailManually = async (prop) => {
    setisLoading(true);
    const emailAddress = prop?.emailAddress;
    const fullName = emailAddress.split("@")[0];
    const firstName = fullName?.split(".")[0];
    const lastName = fullName?.split(".")[1];
    setValues({ ...values, emailAddress, firstName, lastName, staffId: "" });
    setEmailVerified(true);
    setisLoading(false);
  };

  const verifyEmailHandler = async (prop) => {
    if (ADEmailVerify === "true") {
      verifyEmailAPI(prop);
    } else {
      verifyEmailManually(prop);
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setisLoading(true);
    let payload = { ...values };

    try {
      const response = await (isCreate ? adminCreateStaff(payload) : adminUpdateStaff(payload));
      if (response) {
        alertSuccess(response);
        props.closeModal();
        setEmailVerified(false);
        props.reload();
      }
    } catch (error) {
      alertError(error);
    } finally {
      setisLoading(false);
    }
  };

  const closeModal = () => {
    setEmailVerified(false);
    props.closeModal();
  };

  useEffect(() => {
    setValues((prev) => ({ ...prev, ...props.initialValues }));
  }, [props.initialValues]);

  return (
    <ModalLayout
      className="user-modal"
      open={props.open}
      closeModal={closeModal}
      title={props.title}
      isLoading={isLoading}
    >
      {isCreate && !emailVerified && (
        <form className="modal-body" onSubmit={formik.handleSubmit}>
          <DialogContent className="form-content">
            <TextField
              name="emailAddress"
              label="Email"
              id="emailAddress"
              className="input-section"
              type="email"
              fullWidth
              required
              value={formik.values.emailAddress}
              onChange={formik.handleChange}
              error={formik.touched.emailAddress && Boolean(formik.errors.emailAddress)}
              helperText={formik.touched.emailAddress && formik.errors.emailAddress}
            />
          </DialogContent>
          <DialogActions className="modal-actions">
            <Button
              className="action-btn"
              onClick={() => {
                setEmailVerified(false);
                props.closeModal();
              }}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              className="action-btn"
              variant="contained"
              color="primary"
              type="submit"
              disableElevation
              disabled={!formik.values.emailAddress.match(FCMBEmailStructure)}
            >
              Verify Email
            </Button>
          </DialogActions>
        </form>
      )}
      {(emailVerified || isEdit) && (
        <form className="modal-body" onSubmit={submitHandler}>
          <DialogContent className="form-content">
            <TextField
              id="fname"
              className="modal-input"
              label="First Name"
              variant="outlined"
              fullWidth
              value={values?.firstName || ""}
              disabled
            />
            <TextField
              id="lname"
              className="modal-input"
              label="Last Name"
              variant="outlined"
              fullWidth
              value={values?.lastName || ""}
              disabled
            />
            <TextField
              id="email"
              className="modal-input"
              label="Email Address"
              type="email"
              variant="outlined"
              fullWidth
              disabled
              value={values?.emailAddress || ""}
            />
            <TextField
              id="staffId"
              className="modal-input"
              label="Staff ID"
              variant="outlined"
              fullWidth
              value={values?.staffId || ""}
              disabled={isEdit || ADEmailVerify === "true"}
              onChange={inputChangeHandler("staffId")}
            />
            <FormControl required className="modal-select">
              <InputLabel>Role</InputLabel>
              <Select
                id="role"
                value={values.role}
                label="Role"
                onChange={inputChangeHandler("role")}
              >
                {roles.map((role, indx) => (
                  <MenuItem key={indx} value={role}>
                    {role}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions className="modal-actions">
            <Button
              className="action-btn"
              onClick={() => {
                setEmailVerified(false);
                props.closeModal();
              }}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              className="action-btn"
              variant="contained"
              color="primary"
              type="submit"
              disableElevation
            >
              {props.title.toLowerCase().includes("new") ? "Add User" : "Update User"}
            </Button>
          </DialogActions>
        </form>
      )}
    </ModalLayout>
  );
};

export default StaffModal;
