import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { AppRoutes } from "./AppRoutes";

const AuthSystemUser = () => {
  const { role, userType } = useSelector((state) => state?.userAuth?.data);

  const isAdmin = role?.toLowerCase()?.includes("admin");
  const isStaff = userType?.toLowerCase()?.includes("staff");

  return isStaff && isAdmin ? (
    <Navigate to={AppRoutes.staffUsers} />
  ) : (
    <Outlet />
  );
};

export default AuthSystemUser;
