import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import { IMAGES } from "../../../assets";

const GoLive = () => {
  return (
    <>
      <h1 className="section-header">Go Live</h1>
      <p>
        Sign into your{" "}
        <Link to="/login" className="text-purple">
          dashboard
        </Link>{" "}
        and click on the Go LIVE button. Copy your live pulic and secret keys
        and replace the test keys in your setup
      </p>
      <Box
        component="img"
        src={IMAGES.keys}
        alt="access keys"
        className="my-3 lg:w-[70%]"
      />
    </>
  );
};

export default GoLive;
