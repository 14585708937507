import { useSelector } from "react-redux";
import { Box, Button } from "@mui/material";
import { useCallback, useEffect, useState } from "react";

import { alertError, alertSuccess } from "../../../utils/helperFunctions";
import {
  downloadTransactions,
  fetchAllTransactions,
} from "../../../services/actions/transactions.actions";
import NoTableData from "../../../components/tables/NoTableData";
import DateRange from "../../../components/search inputs/DateRange";
import InputSearch from "../../../components/search inputs/InputSearch";
import SelectSearch from "../../../components/search inputs/SelectSearch";
import CustomPagination from "../../../components/tables/CustomPagination";
import TransactionsTable from "../../../components/tables/TransactionsTable";
import TableLayout, { scrollToTableTop } from "../../../layouts/tables/TableLayout";

const TransactionsList = ({ isDownloadable, header }) => {
  const [page, setPage] = useState(1);
  const [tableRows, setTableRows] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setisLoading] = useState(false);
  const [inputSearch, setInputSearch] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchParams, setSearchParams] = useState({});
  const [isDownloading, setisDownloading] = useState(false);
  const { userType, merchantCode } = useSelector((state) => state?.userAuth?.data);

  const isStaff = userType?.toLowerCase() === "staff";

  const searchParamsHandler = (prop) => (e) => {
    setSearchParams({
      ...searchParams,
      [prop]: e.target.value,
    });
  };

  const searchDateParamHandler = (prop, date) => {
    setSearchParams({
      ...searchParams,
      [prop]: date,
    });
  };

  const handleSearch = () => {
    const payload = searchParams;

    if (!isStaff && inputSearch.length > 0) {
      payload.transactionRef = inputSearch;
    }

    if (isStaff && inputSearch.length <= 7) {
      payload.merchantId = inputSearch;
      delete payload?.transactionRef;
    }

    if (isStaff && inputSearch.length > 7) {
      payload.transactionRef = inputSearch;
      delete payload?.merchantId;
    }

    if (inputSearch.length === 0) {
      delete payload?.transactionRef;
      if (isStaff) {
        delete payload?.merchantId;
      }
    }

    setSearchParams(payload);
    fetchTransactions(payload);
  };

  const handleDateReset = () => {
    delete searchParams?.startDate;
    delete searchParams?.endDate;
  };

  const paginationHandler = (prop) => {
    scrollToTableTop();
    setPage(prop);
    fetchTransactions(searchParams, prop);
  };

  const exportHandler = async (prop) => {
    let params = searchParams;
    setisDownloading(true);
    try {
      await downloadTransactions(prop, params);
      alertSuccess("File downloaded");
    } catch (error) {
      alertError(error);
    } finally {
      setisDownloading(false);
    }
  };

  const fetchTransactions = useCallback(async (params, page = 1) => {
    setisLoading(true);
    try {
      const response = await fetchAllTransactions(params, page);
      if (response) {
        setTableRows(response?.data || []);
        setPage(response?.currentPageNumber || 1);
        setTotalPages(response?.totalPages || 0);
        setTotalRecords(response?.totalRecords || 0);
      }
    } catch (error) {
      alertError(error);
    } finally {
      setisLoading(false);
    }
  }, []);

  useEffect(() => {
    let params = {};
    if (!isStaff) {
      params.merchantId = merchantCode;
    }
    setInputSearch("");
    fetchTransactions(params);
    setSearchParams(params);
  }, [fetchTransactions, isStaff, merchantCode]);

  return (
    <Box className="transactions-container">
      <h1 className="page-title">{header}</h1>
      <div className="search-options xl:px-[10px]">
        <InputSearch
          className="inputSearch flex flex-grow min-w-[300px] lg:min-w-[250px]"
          placeholder="Search..."
          value={inputSearch}
          onChange={(e) => setInputSearch(e.target.value)}
        />
        <DateRange
          className="dateRange merchant-transactions flex-grow md:flex-grow-0"
          width={150}
          setDate={searchDateParamHandler}
          onReset={handleDateReset}
        />
        <SelectSearch
          id="transactionChannel"
          label="Channel"
          className="selectBox flex-grow flex-shrink-0 min-w-[120px] md:min-w-[160px] lg:flex-grow-0"
          width={{ sm: 160 }}
          value={searchParams?.PaymentChannel || ""}
          selectItems={channelOptions}
          onSelect={searchParamsHandler("PaymentChannel")}
        />
        <SelectSearch
          id="status"
          label="Status"
          className="selectBox flex-grow flex-shrink-0 min-w-[120px] md:min-w-[160px] lg:flex-grow-0"
          width={{ sm: 160 }}
          value={searchParams.status || ""}
          selectItems={statusOptions}
          onSelect={searchParamsHandler("status")}
        />
        <Box className="action-button flex-grow xl:flex-grow-0 xl:ml-[50px] min-w-[315px]  lg:min-w-[140px] h-[40px]">
          <Button
            sx={{ width: "100%", height: "100%" }}
            variant="outlined"
            disableElevation
            onClick={handleSearch}
            disabled={isLoading || isDownloading}
          >
            Search
          </Button>
        </Box>
      </div>
      <TableLayout isLoading={isLoading}>
        {tableRows?.length < 1 && <NoTableData />}
        {tableRows?.length > 0 && (
          <TransactionsTable
            tableRows={tableRows}
            isDownloading={isDownloading}
            isLoading={isLoading}
            onExport={exportHandler}
            count={totalRecords}
            showDownload={isDownloadable}
          />
        )}
        {tableRows?.length > 0 && totalPages > 1 && (
          <CustomPagination
            paginationHandler={paginationHandler}
            totalPages={totalPages}
            page={page}
          />
        )}
      </TableLayout>
    </Box>
  );
};

export default TransactionsList;

const statusOptions = [
  {
    value: 0,
    text: "Initiated",
  },
  {
    value: 1,
    text: "Successful",
  },
  {
    value: 2,
    text: "Pending",
  },
  {
    value: 3,
    text: "Failed",
  },
];

const channelOptions = [
  {
    value: 0,
    text: "Bank",
  },
  {
    value: 1,
    text: "Card",
  },
  {
    value: 2,
    text: "Pay Later",
  },
];
